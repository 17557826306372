//------ Mixins ------//

@mixin uppercase-text {
    text-transform: uppercase;
    letter-spacing: 0.25em;
}

@mixin heading-font {
    font-family: $primary_600_font;
    font-style: normal;
}

@mixin heading {
    @include heading-font;
    margin: 0;

    &.no-margin {
        margin-top: 0 !important;
    }

    sup {
        font-size: 0.5em;
        vertical-align: super;
    }
}

@mixin heading-1 {
    @include heading;
    font-size: 2em;
    line-height: 1.1em;

    .iphone & {
        letter-spacing: -0.05em;
    }
}

@mixin heading-2 {
    @include heading;
    font-size: 1.8em;
    line-height: 1.2em;

    .iphone & {
        letter-spacing: -0.05em;
    }
}

@mixin heading-3 {
    @include heading;
    font-size: 1.6em;
    line-height: 1.4em;

    .iphone & {
        letter-spacing: -0.05em;
    }
}

@mixin heading-4 {
    @include heading;
    font-size: 1.4em;
    line-height: 1.4em;

    .iphone & {
        letter-spacing: -0.05em;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin breakpoint($point) {
    @if $point == ipad_landscape {
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
            @content;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
            @content;
        }
    }
    @else if $point == ipad_portrait {
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
            @content;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
            @content;
        }
    }
}