//------ Teacher Badges - Section Styles ------//

section {

    &.teacher-badges {

    	div.badges {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: no-wrap;
            gap: 2em;

            div.badge {

                img {
                    max-width: 150px;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.teacher-badges {

            div.badges {
                flex-wrap: wrap;
            }
        }

    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.teacher-badges {

            div.badges {
                
                div.badge {
                    width: calc(50% - 2em);
                }
            }
        }

    }

}