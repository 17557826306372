//------ Comparison Table - Section Styles ------//

section {

    &.comparison-table {

        &.grey-1-bg {

            div.comparison-table {                   

                div.row {
                    border-color: $grey_5;

                    &:last-of-type {

                        div.column-1 {
                            border-color: $grey_5;
                        }

                        div.column-2 {
                            border-color: $grey_5;
                        }

                        div.column-3 {
                            border-color: $grey_5;
                        }
                    }

                    &.header {

                        div.column {

                            &.column-2 {
                                border-color: $grey_5;
                            }

                            &.column-3 {
                                border-color: $grey_5;
                                background-color: $grey_2;

                                span {

                                    &.logo {

                                        svg {

                                            path {
                                                fill: $grey_5;
                                            }
                                        } 
                                    }
                                }
                            }
                        }
                    }

                    &.row-1 {
                        border-color: $grey_5;
                    }

                    div.column {
                        border-color: $grey_5;

                        &.column-3 {
                            background-color: $grey_2;
                        }
                    }
                }
            }       
        }

        &.grey-2-bg {

            div.comparison-table {                   

                div.row {
                    border-color: $grey_5;

                    &:last-of-type {

                        div.column-1 {
                            border-color: $grey_5;
                        }

                        div.column-2 {
                            border-color: $grey_5;
                        }

                        div.column-3 {
                            border-color: $grey_5;
                        }
                    }

                    &.header {

                        div.column {

                            &.column-2 {
                                border-color: $grey_5;
                            }

                            &.column-3 {
                                border-color: $grey_5;
                                background-color: $grey_1;

                                span {

                                    &.logo {

                                        svg {

                                            path {
                                                fill: $grey_5;
                                            }
                                        } 
                                    }
                                }
                            }
                        }
                    }

                    &.row-1 {
                        border-color: $grey_5;
                    }

                    div.column {
                        border-color: $grey_5;

                        &.column-3 {
                            background-color: $grey_1;
                        }
                    }
                }
            } 		
    	}

        &.grey-3-bg,
        &.grey-4-bg,
        &.grey-5-bg {

            div.comparison-table {                   

                div.row {
                    border-color: $white;

                    &:last-of-type {

                        div.column-1 {
                            border-color: $white;
                        }

                        div.column-2 {
                            border-color: $white;
                        }

                        div.column-3 {
                            border-color: $white;
                        }
                    }

                    &.header {

                        div.column {

                            &.column-2 {
                                border-color: $white;
                            }

                            &.column-3 {
                                border-color: $white;
                                background-color: $grey_1;

                                span {

                                    &.logo {

                                        svg {

                                            path {
                                                fill: $grey_5;
                                            }
                                        }
                                    }
                                } 
                            }
                        }
                    }

                    &.row-1 {
                        border-color: $white;
                    }

                    div.column {
                        border-color: $white;
                        color: $white;

                        &.column-3 {
                            background-color: $grey_1;
                        }
                    }
                }
            } 

            a.see-more-btn {
                color: $white;

                svg {

                    path {
                        stroke: $white;
                    }
                }
            }      
        }

    	div.comparison-table {
            width: 100%;                    

            div.row {
                display: flex;
                flex-direction: row;
                border-left: 1px solid $grey_2;  
                border-bottom: 1px solid $grey_2;

                &:last-of-type {
                    border-left: none;
                    border-bottom: none;

                    div.column-1 {
                        border-radius: 0px 0px 0px 20px;
                        border-left: 1px solid $grey_2;
                        border-bottom: 1px solid $grey_2;
                        width: calc(50% + 1px);
                    }

                    div.column-2 {
                        border-bottom: 1px solid $grey_2;
                    }

                    div.column-3 {
                        border-radius: 0px 0px 20px 0px;
                        border-bottom: 1px solid $grey_2;
                    }
                }

                &.header {
                    border-left: none; 
                    border-bottom: none;

                    div.column {
                        justify-content: center;
                        align-items: center;

                        &.column-1 {
                            border: none; 
                            width: 50%; 
                            padding: 0;
                        }

                        &.column-2 {
                            width: 25%; 
                            border: 1px solid $grey_2;
                            border-right: none; 
                            border-bottom: none; 
                            border-radius: 20px 0px 0px 0px;
                            padding: 2em 1em;
                        }

                        &.column-3 {
                            width: calc(25% + 1px); 
                            border: 1px solid $grey_2; 
                            border-bottom: none;  
                            border-radius: 0px 20px 0px 0px;
                            padding: 2em 1em;
                            background-color: $grey_1;

                            span {

                                &.logo {

                                    &.desktop {

                                        svg {
                                            max-width: 150px;
                                        }
                                    }

                                    &.mobile {
                                        display: none;

                                        svg {
                                            max-width: 100px;
                                        }
                                    }

                                    svg {
                                        width: 100%;
                                        text-align: center;

                                        path {
                                            fill: $grey_5;
                                        }
                                    } 
                                }
                            }
                        }

                        span {

                            &.title {
                                font-family: $primary_600_font;
                                font-weight: 600;
                                font-size: 1.2em;
                                line-height: 1.2em;
                                text-align: center;
                                max-width: 250px;
                            }
                        }
                    }
                }

                &.row-1 {
                    border-top: 1px solid $grey_2;
                    border-radius: 20px 0px 0px 0px;
                }

                div.column {
                    padding: 1em;
                    border-right: 1px solid $grey_2;
                    display: flex;
                    align-items: center;

                    span {
                        display: block;

                        &.benefit {
                            line-height: 1.2em;
                        }

                        &.text {
                            font-family: $primary_600_font;
                            font-weight: 600;
                            line-height: 1.2em;
                            text-align: center;
                        }

                        &.disclaimer {
                            font-size: 0.75em;
                            line-height: 1.2em;
                            margin-top: 0.5em;
                            text-align: center;
                        }
                    }

                    &.column-1 {
                        padding: 1em 1em 1em 1.5em;
                        width: 50%;
                    }

                    &.column-2 {
                        width: 25%;
                        flex-direction: column;
                        justify-content: center;

                        svg {
                            max-width: 40px;
                        }
                    }

                    &.column-3 {
                        width: 25%;
                        background-color: $grey_1;
                        flex-direction: column;
                        justify-content: center; 

                        svg {
                            max-width: 40px;
                        }
                    }
                }
            }

            div.hidden-content {
                display: none;
            }
		}

        a.see-more-btn {
            margin-top: 1.5em;
            margin-left: 1.75em;
        }
	}
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.comparison-table {

            div.comparison-table {                   

                div.row {

                    &:last-of-type {

                        div.column-1 {
                            width: calc(35% + 1px);
                        }
                    }

                    &.header {

                        div.column {

                            &.column-1 {
                                width: 40%; 
                            }

                            &.column-2 {
                                width: 30%;
                                padding: 1em;
                            }

                            &.column-3 {
                                width: calc(30% + 1px); 
                                padding: 1em;

                                span {

                                    &.logo {

                                        &.desktop {
                                            display: none;
                                        }

                                        &.mobile {
                                            display: block;
                                        }
                                    }
                                }
                            }

                            span {

                                &.title {
                                    font-size: 1em;
                                    max-width: 200px;
                                }
                            }
                        }
                    }

                    div.column {

                        &.column-1 {
                            padding: 1em;
                            width: 40%;
                        }

                        &.column-2 {
                            width: 30%;
                            padding: 1em 0.5em;
                        }

                        &.column-3 {
                            width: 30%;
                            padding: 1em 0.5em;
                        }
                    }
                }
            }

            a.see-more-btn {
                margin-left: 1.25em;
            }
        }
    }

}