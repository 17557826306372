//------ Teachers - Section Styles ------//

section {

    &.teachers {
        overflow: visible;

    	div.teachers__wrapper {

            aside.teachers__filter {
                max-width: 1000px;
                margin-bottom: 4em;

                form {

                    &.teachers-filter {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-end;

                        div.select-wrapper {

                            &.format {
                                margin-bottom: 0;
                                margin-right: 1em;
                            }
                        }

                        div.input-wrapper {

                            &.location {
                                margin-bottom: 0;
                                margin-right: 1em;
                            }
                        }

                        div.btn-wrapper {
                            margin-top: 0;

                            .btn {

                                &:last-of-type {
                                    margin-left: 1em;
                                }
                            }
                        }
                    }
                }
            }

            .ie & {

                div.teachers__content {

                    div.teachers__articles {
                        
                        article.default-article {
                            float: left;
                            width: 32%;
                            margin-right: 2%;
                            margin-bottom: 2%;

                            &:nth-of-type(3n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
    	}
	}
}

//------ 62.5em = 900px ------//

@media screen and (max-width: 62.5em) {

    section {

        &.teachers {
            overflow: hidden;

            div.teachers__wrapper {

                aside.teachers__filter {
                    margin-bottom: 2em;
                    
                    form {

                        &.teachers-filter {
                            flex-direction: column;
                            margin-bottom: 2em;

                            div.select-wrapper {

                                &.format {
                                    margin-bottom: 1em;
                                    margin-right: 0;
                                }
                            }

                            div.input-wrapper {

                                &.location {
                                    margin-bottom: 1em;
                                    margin-right: 0;
                                }
                            }

                            div.btn-wrapper {
                                display: flex;
                                flex-direction: row;
                                width: 100%;
                                margin-top: 1em;

                                a.btn {
                                    order: 1;
                                }

                                button.btn {
                                    order: 2;
                                }

                                .btn {

                                    &:last-of-type {
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.teachers {

            div.teachers__wrapper {

                div.teachers__articles {

                    &.grid-container {

                        &.grid-one-third {
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.teachers {

            div.teachers__wrapper {

                div.teachers__articles {

                    &.grid-container {

                        &.grid-one-third {
                            grid-template-columns: repeat(1, 1fr);
                        }
                    }
                }
            }
        }
    }

}