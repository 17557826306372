//------ Course Overview - Section Styles ------//

section {

    &.course-overview {

        &.font-roboto {
            font-family: $roboto_regular_font;
            font-weight: 400;

            h1, h2, h3 {
                font-family: $roboto_700_font;
                font-weight: 700;
            }

            span {

                &.heading {
                    font-family: $roboto_700_font;
                    font-weight: 700;
                }
            }
        }

        div.intro-wrapper {
            
            p {
                line-height: 1.6em;
            }
        }

        div.sessions {

        	div.session {
        		background-color: $white;
        		padding: 3em 4em;

                span {

                    &.heading {
                        display: block;
                        color: $blue-1;
                        font-size: 1.2em;
                    }
                }

                h2, h3 {
                    font-size: 1.2em;
                    color: $black;
                    margin-top: 0.75em;

                    &:first-of-type {
                        margin-top: 0.5em;
                    }
                }
                
                a {
                        
                    &:not(.btn) {
                        transition: all 0.2s ease-in-out;
                        border-bottom: 1px solid;
                        font-family: $primary_600_font;
                        font-weight: 600;

                        .no-touchevents &:hover {
                            color: $grey_3;
                            border-color: $grey_3;
                        }
                    }

                    &.btn {
                        margin-top: 1em;
                    }
                }

                ul, ol {
                    padding: 1em 0 0 0;
                    margin: 0 0 0 1.1em;
                }

                ol {

                    li {
                        line-height: 1.4em;
                        margin-bottom: 1em;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
    	    }
        }
	}
}

//------ 62.5em = 1000px ------//

@media screen and (max-width: 62.5em) {

    section {

        &.course-overview {

            div.sessions {

                &.grid-container {

                    &.grid-one-half {
                        grid-template-columns: 100%;
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.course-overview {

            div.intro-wrapper {
                text-align: left;
            }

            div.sessions {

                &.grid-container {

                    &.grid-one-half {
                        grid-template-columns: 100%;
                    }
                }

                div.session {
                    padding: 2em;

                    span {

                        &.heading {
                            font-size: 1em;
                        }
                    }

                    h2, h3 {
                        font-size: 1em;
                        margin-top: 0.5;

                        &:first-of-type {
                            margin-top: 0;
                        }
                    }

                    ul, ol {
                        font-size: 0.9em;
                        line-height: 1.6em;
                    }
                }
            }
        }
    }

}