//------ Featured Blocks - Section Styles ------//

section {

    &.featured-blocks {
        
        div.blocks {

            div.inner-blocks {
                // margin-left: -1em;

                // &.slick-initialized {
                //     opacity: 1;
                //     visibility: visible;

                //     // &.blocks-1,
                //     // &.blocks-2 {
                        
                //     //     div.slick-track {
                //     //         margin: 0;
                //     //     }
                //     // }
                // }

                div.block {
                    // padding-left: 1em;
                    // padding-right: 1em;
                    overflow: hidden;

                    div.block__inner {
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 0;
                        border-radius: 20px;
                        padding-top: 60%;
                        overflow: hidden;

                        a {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            border: none;

                            .no-touchevents &:hover {
                                
                                img {
                                    transform: scale(1.2);
                                }
                            }
                        }

                        h3 {
                            position: absolute;
                            left: 2em;
                            bottom: 2em;
                            background-color: $white;
                            @include uppercase-text;
                            font-size: 0.9em;
                            line-height: 1.6em;
                            padding: 0.5em 1.5em;
                            padding-top: calc(0.5em + 3px);
                            border-radius: 50px;
                            z-index: 2;
                        }

                        img {
                            position: absolute;
                            left: 0;
                            top: -1px;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            transition: all 0.2s ease-in-out;
                        }

                        span {

                            &.overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: block;
                                height: 100%;
                                width: 100%;
                                background-color: $black;
                                border-radius: 20px;
                                overflow: hidden;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

//------ 62.5em = 1000px ------//

@media screen and (max-width: 62.5em) {

    section {

        &.featured-blocks {
            
            div.blocks {

                div.inner-blocks {

                    &.grid-container {

                        &.grid-one-half,
                        &.grid-one-third {
                            grid-template-columns: 100%;
                        }
                    }

                    div.block {

                        div.block__inner {
                            padding-top: 75%;
                        }
                    }
                }
            }
        }
    }   

}

//------ 62.5em = 1000px ------//

// @media screen and (max-width: 62.5em) {

//     section {

//         &.featured-blocks {

//             div.blocks {

//                 div.inner-blocks {

//                     &:not(.blocks-1) {

//                         & > div.slick-list {
//                             padding: 0 90px 0 0;
//                             overflow: visible;
//                         }
//                     }
//                 }
//             }
//         }
//     }

// }

//------ 43.75em = 700px ------//

// @media screen and (max-width: 43.75em) {

//     section {

//         &.featured-blocks {

//             div.blocks {

//                 div.inner-blocks {

//                     &:not(.blocks-1) {

//                         & > div.slick-list {
//                             padding: 0 30px 0 0;
//                         }
//                     }
//                 }
//             }
//         }
//     }

// }