//------ Icon CTA - Section Styles ------//

section {

    &.icon-cta {
        position: relative;
        overflow: hidden;
        text-align: center;

        &.grey-3-bg,
        &.grey-4-bg,
        &.grey-5-bg {
            color: $white;

            div.block {

                div.block__content {

                    p { 
                        color: $white;
                    }
                }
            }
        }

        div.block {

            div.block__icon {
                margin-bottom: 2em;

                img, svg {
                    max-height: 75px;
                    width: auto;
                }
            }

            div.block__content {

                a {

                    &:not(.btn) {
                        transition: all 0.2s ease-in-out;
                        border-bottom: 1px solid;
                        font-family: $primary_600_font;
                        font-weight: 600;

                        .no-touchevents &:hover {
                            color: $grey_3;
                            border-color: $grey_3;
                        }
                    }

                    &.btn {
                        margin-top: 1em;
                    }
                }
            }
        }
    }
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.image-cta {

            div.block {

                div.block__content {

                    br {
                        display: none;
                    }

                    a {

                        &.btn {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

}