//------ FAQs - Section Styles ------//

section {

    &.faqs {

    	&.font-roboto {
    		font-family: $roboto_regular_font;
    		font-weight: 400;

    		h1, h2, h3 {
    			font-family: $roboto_regular_font;
				font-weight: 400;
    		}
    	}

    	&.blue {

    		div.intro-wrapper {
            
	            p {
	                line-height: 1.6em;
	            }
	        }

    		div.faq-list {

	    		&.faqs-1 {
	    			border-color: $grey_2;
	    		}

				div.faq {
					border-color: $grey_2;

					div.faq-heading {

						span {

							&.heading {
								color: $blue_1;
							}

							&.icon {

								svg {

									path {
										fill: $blue_1;
									}
								}
							}
						}
					}

					div.hidden-content {
						padding: 2em;
						background-color: $light_grey;
						margin-bottom: 1.5em;

						p {
							color: $grey_5;
						}

						ul, ol {
							
							li {
								color: $grey_5;
							}
						}
					}
				}
			}
    	}

    	&.grey-3-bg,
    	&.grey-4-bg,
    	&.grey-5-bg {

    		div.faq-list {

	    		&.faqs-1 {
	    			border-color: $white;
	    		}

				div.faq {
					border-color: $white;

					div.faq-heading {

						span {

							&.heading {
								color: $white;
							}

							&.icon {

								svg {

									path {
										stroke: $white;
									}
								}
							}
						}
					}

					div.hidden-content {

						p {
							color: $white;
						}

						ul, ol {
							
							li {
								color: $white;
							}
						}
					}
				}
			}
    	}

    	div.faq-list {

    		&.faqs-1 {
    			border-bottom: 1px solid $grey_2;
    		}

			div.faq {
				border-bottom: 1px solid $grey_2;

				&:last-of-type {
					border-bottom: none;
				}

				&.open {

					div.faq-heading {

	    				span {

	    					&.icon {
	    						transform: scaleY(-1);
	    					}
	    				}
    				}
				}

				div.faq-heading {
					position: relative;
					display: flex;
					flex-direction: row;
    				align-items: flex-start;
    				justify-content: space-between;
    				padding: 1.5em 0;
    				cursor: pointer;

    				span {

    					&.heading {
    						font-size: 1.2em;
    						line-height: 1.4em;
    						padding-right: 0.5em;
    					}

    					&.icon {
							transition: all 0.2s ease-in-out;

							svg {
								width: 15px;
								height: auto;
								margin-top: 10px;

								path {
									fill: $grey_5;
								}
							}
    					}
				   	}
				}

				div.hidden-content {
					display: none;
					margin-bottom: 1.5em;

					p {
						line-height: 1.6em;

						&:first-of-type {
							margin-top: 0;
						}
                    }

                    a {
                            
                        &:not(.btn) {
                            transition: all 0.2s ease-in-out;
                            border-bottom: 1px solid;
                            font-family: $primary_600_font;
                            font-weight: 600;

                            .no-touchevents &:hover {
                                color: $grey_3;
                                border-color: $grey_3;
                            }
                        }

                        &.btn {
                            margin-top: 1em;
                        }
                    }

                    ul, ol {
                        padding: 0.25em 0 0 0;
                        margin: 0 0 0 1.1em;

                        li {
                        	line-height: 1.6em;
                        }
                    }
				}
			}
		}

		div.button-wrapper {
			text-align: center;
			margin-top: 2em;

			a.btn {
				font-family: $roboto_regular_font;
				font-weight: 400;
			}
		}
	}
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

	section {

	    &.faqs {

	    	div.intro-wrapper {
	    		margin-bottom: 2em;
	    		text-align: left;
	    	}

	    	&.blue {
	    		border-top: 1px solid $grey_2;
	    		
	    		div.intro-wrapper {
	    			padding-top: 2em;
	    			margin-bottom: 1em;
	    		}

	    		div.faq-list {

					div.faq {

						div.faq-heading {

							span {

								&.heading {
								    font-size: 0.9em;
								    line-height: 1.4em;
								    padding-right: 20px;
								    margin-top: 4px;
								}
							}
						}

						div.hidden-content {
							padding: 1em;

							p {
								font-size: 0.9em;
							}

							ul, ol {
								font-size: 0.9em;
							}
						}
					}
				}
	    	}

	    	div.faq-list {

				div.faq {

					div.faq-heading {

						span {

							&.heading {
							    font-size: 1em;
							    line-height: 1.4em;
							    padding-right: 20px;
							    margin-top: 4px;
							}
						}
					}

					div.hidden-content {
						padding: 1em;

						a {

			                &.btn {
			                    margin-top: 0;
			                }
			            }
					}
				}
			}
		}
	}

}