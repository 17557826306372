//------ Footer Styles ------//

footer {
    padding: 4em 0;
    color: $white;
    transition: all 0.3s ease-in-out;
    will-change: transform;

    &.grey-3-bg,
    &.grey-4-bg,
    &.grey-5-bg {

        div.footer-wrapper {

            div.footer-wrapper__top {

                nav {

                    &.footer-menu {

                        ul {

                            li {
                                color: $white;
                            }
                        }
                    }
                }
            }

            div.footer-wrapper__bottom {

                div.row {

                    &.top {
                        border-top: 1px solid $white;

                        p {
                            color: $white;
                        }
                    }

                    &.bottom {

                        nav {

                            &.baseline-menu {

                                ul {

                                    li {

                                        a {
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    div.footer-wrapper {

        div.footer-wrapper__top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            nav {

                &.footer-menu {

                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;

                        li {
                            display: inline-block;
                            font-family: $primary_600_font;
                            font-size: 0.9em;
                            @include uppercase-text;
                            margin-right: 30px;
                            transition: all 0.2s ease-in-out;

                            .no-touchevents &:hover {

                                & > a {
                                    color: $orange;
                                }
                            }

                            &.blue {

                                .no-touchevents &:hover {

                                    & > a {
                                        color: $blue;
                                    }
                                }
                            }

                            &.green {

                                .no-touchevents &:hover {

                                    & > a {
                                        color: $green;
                                    }
                                }
                            }

                            &.purple {

                                .no-touchevents &:hover {

                                    & > a {
                                        color: $purple;
                                    }
                                }
                            }

                            &.mauve {

                                .no-touchevents &:hover {

                                    & > a {
                                        color: $mauve;
                                    }
                                }
                            }

                            &.menu-item-has-children {

                                ul.sub-menu { display: none; }
                            }

                            &:last-of-type {
                                margin-right: 0;
                            }

                            a {
                                transition: all 0.2s ease-in-out;
                            }
                        }
                    }
                }

                &.social-menu {

                    ul {
                        display: flex;
                        flex-direction: row;
                        margin-left: 2em;
                    }
                }
            }
        }

        div.footer-wrapper__bottom {

            div.row {

                &.top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                    flex-wrap: wrap;
                    border-top: 1px solid;
                    margin-top: 2em;
                    padding-top: 2em;

                    p {
                        margin: 0;

                        &.copyright {
                            font-size: 0.75em;
                            line-height: 1em;
                            margin-right: 10px;
                        }
                    }

                    div.accreditations {
                        display: flex;
                        flex-direction: row;

                        div.logo {
                            margin-right: 20px;

                            &:last-of-type {
                                margin-right: 0;
                            }

                            a {
                                display: block;

                                svg {
                                    width: 100%;
                                    max-width: 80px;
                                }
                            }
                        }
                    }
                }

                &.bottom {
                    display: flex;
                    flex-direction: row;
                    margin-top: 0.5em;

                    nav {

                        &.baseline-menu {

                            ul {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 10px;
                                list-style-type: none;
                                margin: 0;
                                padding: 0;

                                li {
                                    display: inline-block;
                                    font-size: 0.75em;
                                    line-height: 1em;

                                    a {

                                        .no-touchevents &:hover {
                                            border-bottom: 1px solid;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//------ 64em = 1024px ------//

@media screen and (max-width: 64em) {

    footer {

        div.footer-wrapper {

            div.footer-wrapper__top {
                flex-direction: column;

                nav {

                    &.footer-menu {
                        margin-bottom: 2em;
                        text-align: center;

                        ul {

                            li {
                                display: block;
                                margin-right: 0;
                                margin-bottom: 1em;

                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    &.social-menu {

                        ul {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    footer {
        padding: 2em 0;

        div.footer-wrapper {

            div.footer-wrapper__bottom {

                div.row {

                    &.top {
                        flex-direction: column;
                        align-items: center;

                        p {

                            &.copyright {
                                margin-right: 0;
                            }
                        }

                        div.accreditations {
                            margin-top: 2em;
                        }
                    }

                    &.bottom {
                        margin-top: 2em;
                    }
                }
            }
        }
    }

}