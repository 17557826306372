//------ Grid ------//

.grid-1 { width: 6.5%; }
.grid-2 { width: 15%; }
.grid-3 { width: 23.5%; }
.grid-4 { width: 32%; }
.grid-5 { width: 40.5%; }
.grid-6 { width: 49%; }
.grid-7 { width: 57.5%; }
.grid-8 { width: 66%; }
.grid-9 { width: 74.5%; }
.grid-10 { width: 83%; }
.grid-11 { width: 91.5%; }
.grid-12 { width: 100%; }

.grid-1,
.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-6,
.grid-7,
.grid-8,
.grid-9,
.grid-10,
.grid-11,
.grid-12 {
    margin: 0 2% 0 0;
    float: left;
    display: block;
}

.alpha { margin-left: 0; }
.omega { margin-right: 0; }

.container {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;

    &.container--medium {
        max-width: 1000px;
    }

    &.container--small {
    	max-width: 800px;
    }

    &.container--xsmall {
        max-width: 550px;
    }
}

.grid-container {
    display: grid;

    &.grid-one-fifth {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2em;
        grid-row-gap: 2em;
    }

    &.grid-one-fourth {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2em;
        grid-row-gap: 2em;
    }

    &.grid-one-third {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2em;
        grid-row-gap: 2em;
    }

    &.grid-one-half {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2em;
        grid-row-gap: 2em;
    }

    &.grid-full-width {
        grid-template-columns: 100%;
        grid-template-rows: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 2em;
    }
}

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.clearfix:after {
    clear: both;
    content: '';
    display: block;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
    width: 0;
    height: 0;
}