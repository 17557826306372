//------ Map - Section Styles ------//

section {

	&.map {

		&.grey-3-bg,
	    &.grey-4-bg,
		&.grey-5-bg {

			div.intro-wrapper {

				div.container {
					color: $white;

					h2, h3, h4 {
						color: $white;
					}
				}
			}	
		}

		div.intro-wrapper {

			&.margin-bottom {
				margin-bottom: 4em;
			}
		}

		div.acf-map {
            width: 100%;
            height: 400px;

            img {
               max-width: inherit !important;
            }
        }
	}
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

	section {

		&.map {

			div.intro-wrapper {

				&.margin-bottom {
					margin-bottom: 2em;
				}
			}
		}
	}

}