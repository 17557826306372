//------ Link Blocks - Section Styles ------//

section {

    &.link-blocks {

        &.grey-3-bg,
        &.grey-4-bg,
        &.grey-5-bg {

            div.link-blocks__blocks {
            
                article.default-article {

                    div.text-wrapper {
                        color: $white;

                        p {
                            color: $white;

                            strong {
                                color: $white;
                            } 

                            a {

                                &:not(.btn) {
                                    color: $white;

                                    .no-touchevents &:hover {
                                        color: $white;
                                        border-color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div.link-blocks__blocks {
            
            article.default-article {

                div.text-wrapper {

                    p {

                        a {

                            &:not(.btn) {
                                transition: all 0.2s ease-in-out;
                                border-bottom: 1px solid;
                                font-family: $primary_600_font;
                                font-weight: 600;

                                .no-touchevents &:hover {
                                    color: $grey_3;
                                    border-color: $grey_3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.link-blocks {

            div.link-blocks__blocks {

                &.grid-container {

                    &.grid-one-third {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }   

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.link-blocks {

            div.link-blocks__blocks {

                &.grid-container {

                    &.grid-one-third {
                        grid-template-columns: 100%;
                    }
                }
            }
        }
    } 

}

//------ Media Queries (Exact Match) ------//

@include breakpoint(ipad_portrait) {

    section {

        &.link-blocks {

            div.link-blocks__blocks {

                &.grid-container {

                    &.grid-one-third {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }  

}