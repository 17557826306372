//------ Form Styles ------//

.iphone input,
.iphone textarea,
.iphone select {
    font-size: 16px;
}

div.select-wrapper {

    &.blog-categories {
        position: relative;
        cursor: pointer;
        background-image: url(../assets/icons/chevron-down-icon-grey-5.svg);
        background-repeat: no-repeat;
        background-position: top 50% right 0%;
        background-size: 15px;
        border-bottom: 1px solid $grey_2;
        width: 100%;
        max-width: 350px;
        margin-top: 4em;

        select {
            appearance: none;
            background-color: transparent;
            border: none;
            padding: 5px 0;
            margin: 0;
            width: 100%;
            color: $grey_5;

            &:focus {
                outline: none;
            }

            &::-ms-expand {
                display: none;
            }
        }
    }
}

form {

    textarea {
        min-width: 100%;
        max-width: 100%;
        vertical-align: top;
    }

    &.wpcf7-form {

        & > label {
            display: block;
            font-family: $primary_600_font;
            @include uppercase-text;
            color: $grey_5;
            font-size: 0.9em;
            line-height: 1.4em;
            margin-bottom: 0.5em;

            .grey-3-bg &,
            .grey-4-bg &,
            .grey-5-bg & {
            	color: $white;
            }
        }

        p {
            margin-top: 0;
            margin-bottom: 0.5em;

            &.margin-bottom {
                margin-bottom: 1em;
            }

            .grey-3-bg &,
            .grey-4-bg &,
            .grey-5-bg & {
                color: $white;
            }
        }

        div.two-columns {
            display: block;

            & > label {
                display: block;
                font-family: $primary_600_font;
                @include uppercase-text;
                color: $grey_5;
                font-size: 0.9em;
                line-height: 1.4em;

                .grey-3-bg &,
                .grey-4-bg &,
                .grey-5-bg & {
	            	color: $white;
	            }
            }

            &:after {
                clear: both;
                content: '';
                display: block;
                font-size: 0;
                line-height: 0;
                visibility: hidden;
                width: 0;
                height: 0;
            }

            div.input-wrapper {
                width: calc(50% - 20px);
                margin-right: 20px;
                float: left;

                & > label {
                    display: block;
                    font-family: $primary_600_font;
                    @include uppercase-text;
                    color: $grey_5;
                    font-size: 0.9em;
                    line-height: 1.4em;

                    .grey-3-bg &,
                    .grey-4-bg &,
                    .grey-5-bg & {
		            	color: $white;
		            }
                }

                & > span.wpcf7-form-control-wrap {
                    width: 100%;
                    margin-right: 0;

                    &:nth-of-type(2n) {
                        width: 100%;
                        margin-right: 0;
                    }
                }

                &:nth-of-type(2n) {
                    width: calc(50% - 0px);
                    margin-right: 0;
                }
            }

            span.wpcf7-form-control-wrap {
                width: calc(50% - 20px);
                margin-right: 20px;
                float: left;

                &:nth-of-type(2n) {
                    width: calc(50% - 0px);
                    margin-right: 0;
                }
            }
        }

        input {

            &.wpcf7-submit {
                appearance: none;
                display: inline-block;
                padding: 0.5em 2em;
                margin-top: 0;
                font-family: $primary_600_font;
                line-height: 1.8em;
                @include uppercase-text;
                white-space: nowrap;
                border-radius: 30px;
                border: none;
                transition: all 0.2s ease-in-out;
                color: $orange;
                background-color: transparent;
                border: 1px solid $orange;

                .no-touchevents &:hover {
                    color: $white;
                    background-color: $orange;
                }

                .grey-3-bg &,
                .grey-4-bg &,
                .grey-5-bg & {
	            	color: $white;
	            	background-color: transparent;
	            	border: 1px solid $white;

	            	.no-touchevents &:hover {
	            		color: $grey_5;
	                    background-color: $white;
	                }
	            }
            }
        }

        input[type='text'],
        input[type='number'],
        input[type='tel'],
        input[type='url'],
        input[type='date'],
        input[type='email'],
        textarea {
            appearance: none;
            padding: 10px 0;
            margin-bottom: 2em;
            width: 100%;
            font-family: $primary_regular_font;
            background-color: transparent;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $grey_2;
            color: $grey_5;
            transition: 0.2s all ease-in-out;

            @include placeholder {
                color: $grey_5;
                opacity: 1;
            }

            &.wpcf7-not-valid {
                margin-bottom: 0.25em;
            }

            .grey-3-bg &,
            .grey-4-bg &,
            .grey-5-bg & {
            	color: $white;
            	border-color: $white;

            	@include placeholder {
	                color: $white;
	            }

	            &:focus {
	                border-color: $white;
	                color: $white;

	                @include placeholder {
	                    color: $white;
	                }
	            }
            }
        }

        input[type='date'] {
            
            &::-webkit-calendar-picker-indicator {
                opacity: 0;
            }
        }

        input[type='file'] {
            padding: 2em;
            margin-bottom: 1em;
            border: 1px solid $grey_2;
            width: 100%;

            &::-webkit-file-upload-button {
                display: none;
            }

            .no-touchevents &:hover::before {
                background-color: $grey_5;
                color: $white;
            }

            &:before {
                content: 'Choose file';
                display: inline-block;
                background-color: $white;
                border: 1px solid $grey_5;
                color: $grey_5;
                border-radius: 0;
                margin-right: 10px;
                padding: 5px 8px;
                outline: none;
                white-space: nowrap;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
            }

            .grey-3-bg &,
            .grey-4-bg &,
            .grey-5-bg & {
            	border-color: $white;

            	.no-touchevents &:hover::before {
	                background-color: $white;
	                color: $grey_5;
	            }

            	&:before {
	                background-color: transparent;
	                border: 1px solid $white;
	                color: $white;
	            }
            }
        }

        div.select-wrapper {
            position: relative;
            cursor: pointer;
            background-image: url(../assets/icons/chevron-down-icon-grey-5.svg);
            background-repeat: no-repeat;
            background-position: top 50% right 0%;
            background-size: 15px;
            border-bottom: 1px solid $grey_2;
            width: 100%;
            margin-bottom: 2em;

            .grey-3-bg &,
            .grey-4-bg &,
            .grey-5-bg & {
                background-image: url(../assets/icons/chevron-down-icon-white.svg);
            	border-color: $white;
            }

            &.teachers,
            &.courses,
            &.birth-preferences {
                background-image: unset;
                background-repeat: unset;
                background-position: unset;
                background-size: unset;

                & > label {
                    display: block;
                    font-family: $primary_600_font;
                    @include uppercase-text;
                    color: $grey_5;
                    font-size: 0.9em;
                    line-height: 1.4em;

                    .grey-3-bg &,
                    .grey-4-bg &,
                    .grey-5-bg & {
                    	color: $white;
                    }
                }
            }

            div.select-inner-wrapper {
                background-image: url(../assets/icons/chevron-down-icon-grey-5.svg);
                background-repeat: no-repeat;
                background-position: top 50% right 0%;
                background-size: 15px;

                .grey-3-bg &,
                .grey-4-bg &,
                .grey-5-bg & {
                    background-image: url(../assets/icons/chevron-down-icon-white.svg);
                }
            }

            select {
                appearance: none;
                background-color: transparent;
                border: none;
                padding: 5px 0;
                margin: 0;
                width: 100%;
                color: $grey_5;

                .grey-3-bg &,
                .grey-4-bg &,
                .grey-5-bg & {
	            	color: $white;

                    option {
                        color: $grey_5;
                    }
	            }

                &:focus {
                    outline: none;
                }

                &::-ms-expand {
                    display: none;
                }
            }
        }

        div.radio-wrapper,
        div.checkbox-wrapper {

            & > label {
                display: block;
                font-family: $primary_600_font;
                @include uppercase-text;
                color: $grey_5;
                font-size: 0.9em;
                line-height: 1.4em;
                margin-bottom: 0.5em;
            }
        }

        div.radio-wrapper {
            display: block;
            margin-bottom: 2em;

            span.wpcf7-list-item {
                display: block;
                margin: 0 0 0.5em 0;

                &.last {
                    margin: 0;
                }

                label {
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    color: $grey_5;

                    .grey-3-bg &,
                    .grey-4-bg &,
                    .grey-5-bg & {
		            	color: $white;
		            }

                    &:before {
                        content: '';
                        appearance: none;
                        background-color: transparent;
                        border: 1px solid $grey_5;
                        padding: 10px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 10px;
                        border-radius: 50%;

                        .grey-3-bg &,
                        .grey-4-bg &,
                        .grey-5-bg & {
			            	border-color: $white;
			            }
                    }

                    &.checked {

                        &:before {
                            border-color: $grey_5;
                            background-color: $grey_5;
                        }

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 9px;
                            left: 7px;
                            width: 8px;
                            height: 8px;
                            background-color: $white;
                            border-radius: 50%;

                            .iphone & {
                                top: 8.5px;
                            }
                        }

                        .grey-3-bg &,
                        .grey-4-bg &,
                        .grey-5-bg & {
			            	
			            	&:before {
	                            border-color: $white;
	                            background-color: $white;
	                        }

	                        &:after {
	                            background-color: $grey_5;
	                        }
			            }
                    }

                    input {
                        display: none;
                        height: initial;
                        width: initial;
                        padding: 0;
                        margin-bottom: 0;
                        cursor: pointer;
                    }
                }
            }
        }

        div.checkbox-wrapper {
            display: block;
            margin-bottom: 2em;

            &.wpcf7-acceptance {
                
                span.wpcf7-list-item {
                    margin: 0;
                }
            }

            span.wpcf7-list-item {
                display: block;
                margin: 0 0 0.5em 0;

                &.last {
                    margin: 0;
                }

                label {
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    align-items: flex-start;
                    color: $grey_5;

                    .grey-3-bg &,
                    .grey-4-bg &,
                    .grey-5-bg & {
		            	color: $white;
		            }

                    &:before {
                        content: '';
                        appearance: none;
                        background-color: transparent;
                        border: 1px solid $grey_5;
                        padding: 10px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 10px;
                        border-radius: 0px;

                        .grey-3-bg &,
                        .grey-4-bg &,
                        .grey-5-bg & {
		            		border-color: $white;
		            	}
                    }

                    &.checked {

                        &:before {
                            border-color: $grey_5;
                            background-color: $grey_5;
                        }

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 2px;
                            left: 8px;
                            width: 6px;
                            height: 14px;
                            border: solid $white;
                            border-width: 0 1px 1px 0;
                            transform: rotate(45deg);
                        }

                        .grey-3-bg &,
                        .grey-4-bg &,
                        .grey-5-bg & {
			            	
			            	&:before {
	                            border-color: $white;
	                            background-color: $white;
	                        }

	                        &:after {
	                            border-color: $grey_5;
	                        }
			            }
                    }

                    input {
                        display: none;
                        height: initial;
                        width: initial;
                        padding: 0;
                        margin-bottom: 0;
                        cursor: pointer;
                    }

                    span.wpcf7-list-item-label {

                        &:before,
                        &:after {
                            content: none;
                        }

                        a {
                            border-bottom: 1px solid $grey_5;
                            transition: all 0.2s ease-in-out;

                            .no-touchevents &:hover {
                                color: $grey_3;
                                border-color: $grey_3;
                            }

                            .grey-3-bg &,
                            .grey-4-bg &,
                            .grey-5-bg & {
                            	border-color: $white;

                            	.no-touchevents &:hover {
	                                color: $white;
	                                border-color: $white;
	                            }
                            }
                        }
                    }
                }
            }
        }

        div.wpcf7-response-output {
            font-style: italic;
            line-height: 1.2em;
            margin: 2em 0 0 0;
            padding: 1em;
            border: none;
        }

        span.wpcf7-not-valid-tip {
            font-style: italic;
            margin-bottom: 0.5em;
            color: #721c24;
        }

        &.invalid,
        &.unaccepted {

            div.wpcf7-response-output {
                color: #721c24;
                background-color: #f8d7da;
                border-color: #f5c6cb;
            }
        }

        &.sent-ok,
        &.sent {

            div.wpcf7-response-output {
                color: #155724;
                background-color: #d4edda;
                border-color: #c3e6cb;
            }
        }

        &.submitting {

            span.wpcf7-spinner {
                visibility: visible;
            }
        }

        span.wpcf7-spinner {
            visibility: hidden;
            width: 18px;
            height: 18px;
            clear: both;
            opacity: 1;
            display: inline-block;
            margin: 0 0 0 15px;
            text-indent: -9999em;
            border-top: 4px solid $grey_2;
            border-right: 4px solid $grey_2;
            border-bottom: 4px solid $grey_2;
            border-left: 4px solid $grey_5;
            background-color: transparent;
            animation: spinning 1s infinite linear;
            border-radius: 50%;
            vertical-align: middle;

            &:before {
                content: none;
            }

            .grey-3-bg &,
            .grey-4-bg &,
            .grey-5-bg & {
            	border-top: 4px solid $white;
	            border-right: 4px solid $white;
	            border-bottom: 4px solid $white;
	            border-left: 4px solid $grey_4;
            }
        }
    }

    &.search {
        margin: 2em 0 0 0;
        max-width: 400px;
    }

    &.courses-filter,
    &.teachers-filter,
    &.search,
    &.newsletter-signup {

        label {
            display: block;
            font-family: $primary_600_font;
            @include uppercase-text;
            color: $grey_5;
            font-size: 0.9em;
            line-height: 1.4em;
        }

        div.two-columns {
            display: block;

            &.flex-end {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
            }

            &:after {
                clear: both;
                content: '';
                display: block;
                font-size: 0;
                line-height: 0;
                visibility: hidden;
                width: 0;
                height: 0;
            }

            div.input-wrapper {
                width: calc(50% - 20px);
                margin-right: 20px;
                float: left;

                & > label {
                    display: block;
                    font-family: $primary_600_font;
                    @include uppercase-text;
                    color: $grey_5;
                    font-size: 0.9em;
                    line-height: 1.4em;
                }

                &:nth-of-type(2n) {
                    width: calc(50% - 0px);
                    margin-right: 0;
                }
            }
        }

        div.input-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 1em;

            &.last {
                margin-bottom: 0;
            }

            div.input-inner-wrapper {
                position: relative;
                z-index: 1;

                span {

                    &.icon {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 20px;
                        height: 20px;
                        cursor: pointer;

                        svg {

                            path {
                                fill: $grey_5;
                            }
                        }

                        .no-touchevents &:hover {

                            svg {

                                path {
                                    transition: all 0.2s ease-in-out;
                                    fill: $grey_3;
                                }
                            }

                            span {

                                &.tooltip {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }

            &.date {

                div.input-inner-wrapper {

                    input {

                        .iphone & {
                            min-height: 41px;

                            // &:after {
                            //     content: attr(placeholder);
                            //     position: absolute;
                            //     left: 0;
                            // }

                            // &.has-value:after,
                            // &:focus:after {
                            //     content: none;
                            // }

                            &::-webkit-date-and-time-value {
                                text-align: left;
                            }
                        }
                    }

                    span {

                        &.icon {
                            width: 15px;
                            height: 18px;
                        }
                    }
                }
            }

            &.location {
                position: relative;

                &.font-roboto {

                    input {
                        font-family: $roboto_regular_font;
                        font-weight: 400;
                    }
                }

                &.font-roboto-700 {

                    input {
                        font-family: $roboto_700_font;
                        font-weight: 700;
                    }
                }

                &.with-icon {
                    background-image: url(../assets/icons/map-icon.svg);
                    background-repeat: no-repeat;
                    background-position: top 40% left 0%;
                    background-size: 14px;

                    input { 
                        padding-left: 25px;
                    }
                }

                &.black {

                    input {
                        color: $black;

                        @include placeholder {
                            color: $black;
                        }
                    }

                    span {

                        &.icon {

                            svg {

                                path {
                                    fill: $black;
                                }
                            }

                            .no-touchevents &:hover {

                                svg {

                                    path {
                                        fill: $grey_3;
                                    }
                                }
                            }

                            span {

                                &.tooltip {
                                    background-color: $black;

                                    &:after {
                                        border-color: $black transparent transparent transparent;
                                    }
                                }
                            }
                        }
                    }
                }

                input {
                    padding: 10px 20px 10px 0px;
                }

                &.loading-location {
                    overflow: hidden;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 10%;
                        height: 1px;
                        z-index: 1;
                        background-color: $grey_5;
                        animation: loading 2s linear infinite;
                    }

                    span {

                        &.icon {

                            svg {
                                animation: rotation-scale 1s infinite linear;
                            }

                            .no-touchevents &:hover {

                                svg {

                                    path {
                                        fill: $grey_5;
                                    }
                                }
                            }
                        }

                        span {

                            &.tooltip {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.search {
                margin-bottom: 0;
            }

            input {
                appearance: none;
                background-color: transparent;
                padding: 10px 0;
                margin: 0;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $grey_2;
                width: 100%;
                text-overflow: ellipsis;

                @include placeholder {
                    color: $grey_5;
                    opacity: 1;
                }
            }
        }

        div.select-wrapper {
            display: flex;
            flex-direction: column;
            min-width: 150px;
            margin-bottom: 1em;

            &.font-roboto {

                select {
                    font-family: $roboto_regular_font;
                    font-weight: 400;
                }
            }

            &.with-border {
                width: 100%;

                div.select-inner-wrapper {
                    border-bottom: 1px solid $grey_2;
                }
            }

            &.arrow {

                &.black {
                    color: $black;

                    div.select-inner-wrapper {
                        background-image: url(../assets/icons/dropdown-icon-black.svg);
                        background-size: 10px;
                    }
                }
            }

            div.select-inner-wrapper {
                position: relative;
                cursor: pointer;
                background-image: url(../assets/icons/chevron-down-icon-grey-5.svg);
                background-repeat: no-repeat;
                background-position: top 50% right 0%;
                background-size: 15px;

                .grey-3-bg &,
                .grey-4-bg &,
                .grey-5-bg & {
                	background-image: url(../assets/icons/chevron-down-icon-white.svg);
                	border-color: $white;
                }

                select {
                    appearance: none;
                    background-color: transparent;
                    border: none;
                    padding: 5px 0;
                    margin: 0;
                    width: 100%;
                    text-overflow: ellipsis;

                    &:focus {
                        outline: none;
                    }

                    &::-ms-expand {
                        display: none;
                    }
                }
            }
        }

        div.btn-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 2em;
        }
    }

    &.courses-filter {

        div.input-wrapper {

            &.location {

                &.with-icon {
                    background-image: url(../assets/icons/map-icon-grey-alt.svg);

                    &.has-value {
                        background-image: url(../assets/icons/map-icon.svg);
                    }
                }

                &.loading-location {

                    &:after {
                        background-color: $black;
                        height: 4px;
                    }

                    span {

                        &.icon {

                            svg {

                                path {
                                    fill: $black !important;
                                }
                            }
                        }
                    }
                }

                div.input-inner-wrapper {

                    input {
                        border-bottom: 4px solid $grey_alt;

                        @include placeholder {
                            color: $grey_alt;
                            opacity: 1;
                        }
                    }

                    span {

                        &.icon {

                            .no-touchevents &:hover {

                                svg {

                                    path {
                                        fill: $black;
                                    }
                                }
                            }

                            svg {

                                path {
                                    fill: $grey_alt;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.course-booking {
        margin-top: 4em;
    }

    &.course-booking,
    &.teacher-enquiry {

        label {
            display: block;
            font-family: $primary_600_font;
            @include uppercase-text;
            color: $grey_5;
            font-size: 0.9em;
            line-height: 1.4em;
        }

        p {
            margin-top: 0.25em;
        }

        div.partner-fields {

            &.hidden {
                display: none;
            }
        }

        div.input-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 2em;

            &.hidden {
                display: none;
            }

            div.input-inner-wrapper {
                position: relative;
                z-index: 1;

                span {

                    &.icon {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 20px;
                        height: 20px;
                        cursor: pointer;

                        svg {

                            path {
                                fill: $grey_5;
                            }
                        }

                        .no-touchevents &:hover {

                            svg {

                                path {
                                    transition: all 0.2s ease-in-out;
                                    fill: $grey_3;
                                }
                            }

                            span {

                                &.tooltip {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }

            &.date {

                div.input-inner-wrapper {

                    input {

                        .iphone & {
                            min-height: 41px;

                            // &:after {
                            //     content: attr(placeholder);
                            //     position: absolute;
                            //     left: 0;
                            // }

                            // &.has-value:after,
                            // &:focus:after {
                            //     content: none;
                            // }

                            &::-webkit-date-and-time-value {
                                text-align: left;
                            }
                        }
                    }

                    span {

                        &.icon {
                            width: 15px;
                            height: 18px;
                        }
                    }
                }
            }

            &.location {
                position: relative;

                input {
                    padding: 10px 20px 10px 0px;
                }

                &.black {

                    &.loading-location {

                        &:after {
                            background-color: $black;
                        }
                    }
                }

                &.loading-location {
                    overflow: hidden;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 10%;
                        height: 1px;
                        z-index: 1;
                        background-color: $grey_5;
                        animation: loading 2s linear infinite;
                    }

                    span {

                        &.icon {

                            svg {
                                animation: rotation-scale 1s infinite linear;
                            }

                            .no-touchevents &:hover {

                                svg {

                                    path {
                                        fill: $grey_5;
                                    }
                                }
                            }
                        }

                        span {

                            &.tooltip {
                                display: none;
                            }
                        }
                    }
                }
            }

            input {
                appearance: none;
                background-color: transparent;
                padding: 10px 0;
                margin: 0;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $grey_2;
                width: 100%;
                text-overflow: ellipsis;

                @include placeholder {
                    color: $grey_5;
                    opacity: 1;
                }
            }
        }

        div.textarea-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 2em;

            &.hidden {
                display: none;
            }

            textarea {
                appearance: none;
                padding: 10px 0;
                margin: 0;
                width: 100%;
                font-family: $primary_regular_font;
                background-color: transparent;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $grey_2;
                color: $grey_5;
                transition: 0.2s all ease-in-out;

                @include placeholder {
                    color: $grey_5;
                    opacity: 1;
                }
            }
        }

        div.select-wrapper {
            display: flex;
            flex-direction: column;
            min-width: 150px;
            margin-bottom: 2em;

            &.with-border {
                width: 100%;

                div.select-inner-wrapper {
                    border-bottom: 1px solid $grey_2;
                }
            }

            div.select-inner-wrapper {
                position: relative;
                cursor: pointer;
                background-image: url(../assets/icons/chevron-down-icon-grey-5.svg);
                background-repeat: no-repeat;
                background-position: top 50% right 0%;
                background-size: 15px;

                .grey-3-bg &,
                .grey-4-bg &,
                .grey-5-bg & {
                    background-image: url(../assets/icons/chevron-down-icon-white.svg);
                    border-color: $white;
                }

                select {
                    appearance: none;
                    background-color: transparent;
                    border: none;
                    padding: 10px 0 5px 0;
                    margin: 0;
                    width: 100%;

                    &:focus {
                        outline: none;
                    }

                    &::-ms-expand {
                        display: none;
                    }
                }
            }
        }

        div.checkbox-wrapper {
            display: block;
            margin-bottom: 2em;

            &.communication-consent {

                label {
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    align-items: flex-start;
                    color: $grey_5;
                    text-transform: none;
                    letter-spacing: 0;
                    font-family: $primary_regular_font;

                    &:before {
                        content: '';
                        appearance: none;
                        background-color: transparent;
                        border: 1px solid $grey_5;
                        padding: 10px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 10px;
                        border-radius: 0px;
                    }

                    &.checked {

                        &:before {
                            border-color: $grey_5;
                            background-color: $grey_5;
                        }

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 2px;
                            left: 8px;
                            width: 6px;
                            height: 14px;
                            border: solid $white;
                            border-width: 0 1px 1px 0;
                            transform: rotate(45deg);
                        }
                    }

                    input {
                        display: none;
                        height: initial;
                        width: initial;
                        padding: 0;
                        margin-bottom: 0;
                        cursor: pointer;
                    }

                    span {
                        margin-top: 3px;

                        &:before,
                        &:after {
                            content: none;
                        }

                        a {
                            border-bottom: 1px solid $grey_5;
                            transition: all 0.2s ease-in-out;

                            .no-touchevents &:hover {
                                color: $grey_3;
                                border-color: $grey_3;
                            }
                        }
                    }
                }
            }
        }

        div.btn-wrapper {
            margin-top: 2em;
        }
    }
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    div.select-wrapper {

        &.blog-categories {
            margin-top: 2em;
        }
    }

    form {

        &.wpcf7-form {

            div.two-columns {

                div.input-wrapper {
                    width: 100%;
                    margin-right: 0;

                    &:nth-of-type(2n) {
                        width: 100%;
                    }
                }

                span.wpcf7-form-control-wrap {
                    width: 100%;
                    margin-right: 0;

                    &:nth-of-type(2n) {
                        width: 100%;
                    }
                }
            }
        }

        &.courses-filter,
        &.teachers-filter,
        &.search,
        &.newsletter-signup {

            div.two-columns {

                div.input-wrapper {
                    width: 100%;
                    margin-right: 0;

                    &:nth-of-type(2n) {
                        width: 100%;
                    }
                }
            }
        }
    }

}