//------ PregnaHub Blocks - Section Styles ------//

section {

    &.pregnahub-blocks  {

        div.intro-wrapper {

            &.margin-bottom {
                margin-bottom: 2.5em;
            }
        }

        div.blocks {

            div.blocks__top {
                display: flex;
                flex-direction: row;

                div.block {
                
                    &.block-1 {
                        width: calc(68% - 2em);
                        margin-top: 0;
                        margin-right: 2em;

                        div.text-wrapper {
                            top: 0;
                            bottom: unset;
                        }
                    }

                    &.block-2 {
                        width: 32%;
                        margin-top: 0;
                        margin-right: 0;
                    }
                }
            }

            div.blocks__bottom {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                div.block {

                    &:nth-of-type(3n) {
                        width: 32%;
                        margin-right: 0;
                    }
                }
            }

            div.block {
                position: relative;
                display: block;
                width: calc(34% - 2em);
                margin-top: 2em;
                margin-right: 2em;
                height: 0;
                border-radius: 20px;
                padding-top: 25%;
                background-color: $grey-1;
                transition: all 0.2s ease-in-out;
                overflow: hidden;

                &.full-width {

                    img {
                        height: 100%;
                        width: 100%;
                    }

                    div.text-wrapper {
                        color: $white;
                    }

                    span.overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        height: 100%;
                        width: 100%;
                        background-color: $black;
                        opacity: 0.2;
                        z-index: 2;
                    }

                    a {

                        .no-touchevents &:hover {
                            
                            img {
                                transform: scale(1.2);
                            }
                        }
                    }
                }

                &.offset {
                    overflow: visible;

                    img {
                        top: -2em;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: 0 1em;
                    }
                }

                &.static {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: inherit;
                    padding-top: unset;
                    overflow: hidden;

                    a {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        position: static;
                        top: unset;
                        left: unset;
                    }

                    img {
                        display: block;
                        margin: 0 auto;
                        position: static;
                        top: unset;
                        left: unset;
                        padding: 0;
                        border-radius: 20px;
                        order: 1;
                    }

                    div.text-wrapper {
                        position: static;
                        left: unset;
                        bottom: unset;
                        order: 2;
                    }
                }

                a {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border: none;

                    .no-touchevents &:hover {

                        div.text-wrapper {

                            span {

                                &.btn {
                                    background-color: lighten($orange, 10%);
                                }
                            }
                        }
                    }
                }

                div.image-wrapper {
                    padding: 1.5em 2em 0 2em;
                    overflow: hidden;
                }

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                    z-index: 1;
                    transition: all 0.2s ease-in-out;
                }

                div.text-wrapper {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 1.5em 2em;
                    z-index: 3;

                    p {

                        &:first-of-type {
                            margin-top: 0;
                        }
                    }

                    span {

                        &.btn {
                            margin-top: 0.5em;
                        }
                    }
                }
            }
        }
    }
}

//------ 62.5em = 1000px ------//

@media screen and (max-width: 62.5em) {


    section {

        &.pregnahub-blocks  {

            div.intro-wrapper {

                &.margin-bottom {
                    margin-bottom: 2em;
                }
            }

            div.blocks {

                div.blocks__top {
                    flex-direction: column;

                    div.block {
                    
                        &.block-1 {
                            width: 100%;
                            margin-right: 0;
                        }

                        &.block-2 {
                            width: 100%;
                            margin-top: 2em;
                        }
                    }
                }

                div.blocks__bottom {
                    flex-direction: column;

                    div.block {

                        &:nth-of-type(3n) {
                            width: 100%;
                        }
                    }
                }

                div.block {
                    position: relative;
                    display: block;
                    width: 100%;
                    margin-right: 0;
                    padding-top: 75%;

                    div.image-wrapper {
                        padding: 1.5em 1.5em 0 1.5em;
                    }
                }
            }
        }
    }

}