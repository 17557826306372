//------ Membership Options - Section Styles ------//

section {

    &.membership-options {

        div.memberships {
            opacity: 0;
            visibility: hidden;

        	&.slick-initialized {
                opacity: 1;
                visibility: visible;
            }
        	
            div.membership {
            	padding: 2em;
                margin-left: 1em;
                margin-right: 1em;
                text-align: center;
                border-radius: 20px;
                border: 0.75vw solid $green;

                h3 {
                    font-size: 0.9em;
                    @include uppercase-text;
                }

                p {

                    &.small {
                        font-style: italic;
                    }
                }

                span {

                    &.price {
                        display: block;
                        font-family: $primary_600_font;
                        font-size: 1.6em;
                        margin-top: 0.5em;
                    }

                    &.saving {
                        display: block;
                        font-size: 0.9em;
                        line-height: 1.4em;
                        font-family: $primary_600_font;
                        @include uppercase-text;
                    }

                    &.benefits__title {
                        display: block;
                        font-size: 0.9em;
                        font-family: $primary_600_font;
                        @include uppercase-text;
                        padding-top: 1.5em;
                        margin-top: 1.5em;
                        border-top: 1px solid $grey_2;
                        text-align: left;
                    }
                }

                ul.benefits__list {
                    list-style-type: none;
                    margin: 1em 0 0 0;

                    li.benefit {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        margin-bottom: 1em;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        span {

                            &.icon {
                                width: 100%;
                                max-width: 30px;
                                margin-right: 10px;

                                svg {

                                    path {
                                        fill: $white;
                                    }

                                    circle {
                                        fill: $grey_5;
                                    }
                                }
                            }

                            &.text {
                                margin-top: 4px;
                                text-align: left;
                            }
                        }  
                    }
                }

                a {

                    &.btn {
                        margin-top: 1.5em;
                    }
                }
            }
        }
    }
}

//------ 62.5em = 1000px ------//

@media screen and (max-width: 62.5em) {

	section {

	    &.membership-options {

	        div.memberships {

	        	&:not(.memberships-1) {

	                & > div.slick-list {
	                    padding: 0 90px 0 0;
	                    overflow: visible;
	                }
            	}
            }
        }
    }

}

//------ 43.75em = 700px ------//

@media screen and (max-width: 43.75em) {

    section {

        &.membership-options {

            div.memberships {

                &:not(.memberships-1) {

                    & > div.slick-list {
                        padding: 0 30px 0 0;
                    }
                }
            }
        }
    }

}