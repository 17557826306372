//------ Landing Banner - Section Styles ------//

section {

    &.landing-banner {
        position: relative;
        min-height: 400px;
        background-color: $grey_1;

        &.has-image {

            div.text-wrapper {

                div.inner-text-wrapper {
                    color: $white;

                    a {

                        &.logo {

                            svg {

                                path,
                                ellipse {
                                    fill: $white;
                                }
                            }
                        }
                    }
                }
            }
        }

        div.image-wrapper {
            overflow: hidden;
            height: 100%;
            height: 100vh;

            .iphone & {
                height: calc(100% - 115px);
                height: calc(100vh - 115px);
            }

            span {

                &.overlay {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-color: $black;
                    z-index: 3;
                }
            }

            img {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: all 0.2s ease-in-out;
                z-index: 0;

                &.default-image {
                    opacity: 1;
                    visibility: visible;
                    z-index: 1;
                }

                &.active {
                    opacity: 1;
                    visibility: visible;
                    z-index: 2;
                }
            }
        }

        div.text-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 4;

            div.inner-text-wrapper {
                margin: 0 auto;
                max-width: 600px;
                text-align: center;
                color: $grey_5;

                a {

                    &.logo {
                        display: block;

                        svg {
                            width: 100%;
                            max-width: 400px;

                            path,
                            ellipse {
                                fill: $grey_5;
                            }
                        }
                    }
                }

                div.website-links {
                    display: flex;
                    flex-direction: row;
                    margin-top: 4em;

                    a {

                        &.btn {
                            margin-right: 30px;

                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.landing-banner {

            div.text-wrapper {

                div.inner-text-wrapper {

                    a {

                        &.logo {

                            svg {
                                max-width: 300px;
                            }
                        }
                    }
                }
            }
        }
    }

}

//------ Media Queries (Exact Match) ------//

@include breakpoint(ipad_portrait) {

    section {

        &.landing-banner {
            max-height: 750px;
        }
    }

}

@include breakpoint(ipad_landscape) {

    section {

        &.landing-banner {
            max-height: 750px;
        }
    }

}