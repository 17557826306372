//------ What's Included - Section Styles ------//

section {

    &.whats-included {

        &.font-roboto {
            font-family: $roboto_regular_font;
            font-weight: 400;
        }

        div.intro-wrapper {
            
            p {
                line-height: 1.6em;
            }
        }

        div.whats-included-list {
            
            div.included {
                display: flex;
                flex-direction: row;

                span.icon {
                	width: 25px;
                	min-width: 25px;
                }

                p {
                	margin: 2px 0px 0 1em;
                    line-height: 1.4em;
                }
            }
        }
	}
}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.whats-included {

            div.whats-included-list {
                
                &.grid-container {

                    &.grid-one-third {
                        grid-template-columns: 100%;
                        grid-row-gap: 1em;
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.whats-included { 

            div.intro-wrapper {
                text-align: left;
            }

            div.whats-included-list {
            
                div.included {

                    p {
                        margin: 4px 0px 0 1em;
                        font-size: 0.9em;
                    }
                }
            }
        }
    }

}