//------ Courses CTA - Section Styles ------//

section {

	&.courses-cta {

		div.courses-cta__top {
			position: relative;

			div.image-wrapper {
				position: relative;
                display: block;
                width: 100%;
             	min-height: 450px;
                border-radius: 20px;
                overflow: hidden;
                background-color: $grey-1;
                box-shadow: 0 6px 6px 4px rgba(0, 0, 0, 0.10);

				img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    transition: all 0.2s ease-in-out;
                    z-index: 1;
                }

                div.text-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: center;
					position: absolute;
					top: 0;
					height: 100%;
					padding: 2em;
					text-align: center;
					z-index: 2;

					&.left {
						left: 0;
						width: 50%;
					}

					&.middle {
						width: 100%;
					}

					&.right {
						right: 0;
						width: 50%;
					}

					div.text-wrapper__inner {
						width: 100%;
						max-width: 550px;
						margin: 0 auto;
						border-radius: 20px;
						background-color: $grey-1;

						h2 {
							font-size: 3em;
						}
					}
				}
			}
		}

		div.courses-cta__bottom {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: 0 4em;
			padding: 2em;
			border-radius: 0px 0px 20px 20px;

			div.column {

				&.left {
					max-width: 650px;
					color: $white;

					p:first-of-type {
						margin-top: 0;
						margin-bottom: 0.5em;
					}
				}

				&.right {
					padding-left: 4em;

					a.btn {
						margin-top: 35px;

						&.solid-white {
							color: $orange;
						}
					}
				}
			}
		}
	}
}

//------ 56.25em = 900px ------//

@media screen and (max-width: 56.25em) {

	section {

		&.courses-cta {

			div.courses-cta__top {

				div.image-wrapper {

	                div.text-wrapper {
	                	padding: 2em 0;

	                	&.left,
	                	&.right {
							width: 100%;
						}

						div.text-wrapper__inner {
							max-width: unset;
							border-radius: 0;
							padding: 1em 0;
						}
					}
				}
			}

			div.courses-cta__bottom {
				flex-direction: column;
				text-align: center;

				div.column {

					&.left {
						max-width: unset;
					}

					&.right {
						padding-left: 0;

						a.btn {
							margin-top: 1em;
						}
					}
				}
			}
		}
	}

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

	section {

		&.courses-cta {

			div.courses-cta__top {

				div.image-wrapper {
             		min-height: 500px;

	                div.text-wrapper {

	                	div.text-wrapper__inner {
	                		padding: 1em;
						
							h2 {
								font-size: 2em;
							}

							br {
								display: none;
							}
						}
					}
				}
			}

			div.courses-cta__bottom {
				margin: 0 1em;
				padding: 2em 1em;

				div.column {

					&.left {

						br {
							display: none;
						}
					}
				}
			}
		}
	}

}