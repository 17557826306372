//------ Fancybox Styles ------//

div.fancybox-container {

	div.fancybox-bg {
	    background: rgba(0, 0, 0, 0.6);
	}

	div.fancybox-inner {

		div.fancybox-toolbar,
		div.fancybox-navigation {

			button.fancybox-button {
    			background: $grey_5;
    			opacity: 1 !important;

    			svg {
    				
    				path {
    					fill: $white;
    				}
    			}
    		}
		}

		div.fancybox-navigation {

			button.fancybox-button {
    			height: 45px;
    			width: 45px;
    			padding: 0;

    			&:disabled {
    				display: none;
    			}
    		}
		}

		div.fancybox-stage {

			div.fancybox-slide {

				button.fancybox-button {
					background: $grey_5;
					opacity: 1;
		
					svg {
						
						path {
							transition: all 0.3s ease-in-out;
				    		fill: $white;
						}
					}

					.no-touchevents &:hover {

						svg {
							
							path {
					    		fill: $white;
							}
						}
					}
				}
			}
		}
	}
}