//------ Pregnahub Banner - Section Styles ------//

section {

    &.pregnahub-banner {
        position: relative;
        padding-top: 154px;

        .loaded & {
            padding-top: var(--hh); 
        }

        div.text-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2em;
            margin-bottom: -11%;
            z-index: 1;

            div.text-wrapper__inner {
                max-width: 650px;

                h1 {
                    font-size: 3em;
                }

                p {

                    &:first-of-type {
                        margin-top: 0;

                        &.uppercase {
                            margin-bottom: 0.5em;
                        }
                    }

                    a {

                        &:not(.btn) {
                            transition: all 0.2s ease-in-out;
                            border-bottom: 1px solid;
                            font-family: $primary_600_font;
                            font-weight: 600;

                            .no-touchevents &:hover {
                                color: $grey_3;
                                border-color: $grey_3;
                            }
                        }
                    }

                    &.btn-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 15px;

                        & > a.btn {

                            &:nth-of-type(2n) {
                                margin-left: 0;
                            }
                        }
                    }
                }

                ul, ol {

                    &:not(.menu) {
                        padding: 1em 0;
                        margin: 0 0 0 1.1em;
                    }
                }

                ol {

                    li {
                        margin-bottom: 1em;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        div.media-wrapper {

            div.media-wrapper__mobile {
                display: none;
            }

            img {

                &.hide-mobile {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                    max-width: 3000px;
                }
            }
        }
    }
}   

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.pregnahub-banner {

            &:before {
                content: '';
                background-image: url(../assets/illustrations/pathlines.svg);
                background-repeat: no-repeat;
                background-position: top center;
                background-size: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                width: 225px;
                height: 100%;
                transform: translateX(-50%);
            }

            div.text-wrapper {
                width: 90%;
                max-width: 1400px;
                padding-top: 1em;
                margin: 0 auto 2em auto;
                background-color: $grey-1;

                div.text-wrapper__inner {

                    h1 {
                        font-size: 2em;
                    }  

                    br {
                        display: none;
                    } 
                }
            }

            div.media-wrapper {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 50%;
                    background-color: $white;
                }

                div.media-wrapper__mobile {
                    position: relative;
                    display: block;
                    width: 90%;
                    max-width: 1400px;
                    margin: 0 auto;
                    text-align: center;
                    z-index: 1;
                }

                img {
                    
                    &.hide-mobile {
                        display: none;
                    }
                }
            }
        }
    }  

}

//------ 28.125em = 450px ------//

@media screen and (max-width: 28.125em) {

    section {

        &.pregnahub-banner {

            &:before {
                left: unset;
                right: 65px;
                transform: none;
            }
        }
    }

}