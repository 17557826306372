//------ Testimonials - Section Styles ------//

section {

    &.testimonials {

        &.grey-3-bg,
        &.grey-4-bg,
        &.grey-5-bg {

            div.testimonials__blocks {

                div.testimonials__inner-blocks {
            
                    div.block {
                        border: none;

                        div.text-wrapper {

                            span {
                                
                                &.author {
                                    color: $grey_5;
                                }
                            }

                            p {
                                color: $grey_5;
                            }
                        }
                    }

                    ul.slick-dots {

                        li {

                            button {
                                border-color: $white;
                            }

                            .no-touchevents &:hover,
                            &.slick-active {
                                
                                button {
                                    background-color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }

        div.testimonials__blocks {

            div.testimonials__inner-blocks {
                opacity: 0;
                visibility: hidden;

                &.slick-initialized {
                    opacity: 1;
                    visibility: visible;
                }
        	
                div.block {
                    padding: 2em;
                    margin: 0 1em;
                    background-color: $grey_1;
                    border-radius: 20px;

                    div.image-wrapper {
                        position: relative;
                        display: block;
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-bottom: 1em;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }

                ul.slick-dots { 
                    display: none;

                    &.show {
                        display: flex !important;
                    }
                }
            }   
        }
    }
}


//------ 75em = 1200px ------//

@media screen and (max-width: 75em) {

    section {

        &.testimonials {

            div.testimonials__blocks {

                div.testimonials__inner-blocks {
                
                    div.block {
                        padding: 2em;
                    }
                }
            }
        }
    }

}