//------ Logos/Icons - Section Styles ------//

section {

	&.logos {

		&.grey-3-bg,
	    &.grey-4-bg,
		&.grey-5-bg {

			div.logo-list {

				div.logo {

					p {
						color: $white;		
					}
				}
			}
		}

		div.container {

			&.grey-1-bg,
			&.grey-2-bg,
			&.grey-3-bg,
	    	&.grey-4-bg,
			&.grey-5-bg {
				width: 94%;
				width: fit-content;
			    padding: 4em;
			    border-radius: 20px;
			}
		}

	    div.logo-list {
	    	display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			
			div.logo {
				text-align: center;
				min-width: 220px;

				&.greyscale {

			        img {
			        	filter: grayscale(100%);
			        }
			    }

			    &.big {
			    	padding: 4%;

			    	img {
			    		max-height: 150px;
			    	}
			    }

			    &.small {
			    	padding: 2%;

			    	img {
			    		max-height: 75px;
			    	}
			    }

			    img {
					width: auto;
					max-width: 200px;
					object-fit: contain;

					&.svg {
						width: 100%;
					}
				}

				p {
					margin-top: 2em;
				}
			}
		}
	}
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

		&.logos {

			div.container {

				&.grey-1-bg,
				&.grey-2-bg,
				&.grey-3-bg,
		    	&.grey-4-bg,
				&.grey-5-bg {
					padding: 2em;
					width: 94%;
				}
			}

		    div.logo-list {	
		    	display: grid;
		    	justify-content: unset;
				align-items: unset;
				flex-wrap: unset;	

				&.odd {
					grid-template-columns: 100%;
			        grid-template-rows: 1fr;
			        grid-column-gap: 0;
			        grid-row-gap: 2em;
				}

				&.even {
					grid-template-columns: repeat(2, 1fr);
			        grid-template-rows: 1fr;
			        grid-column-gap: 2em;
			        grid-row-gap: 2em;
				}

				div.logo {
					min-width: unset;

					&.big {

				    	img {
					    	max-height: 75px;
				    	}
				    }

				    img {
						max-width: 150px;
					}
				}
			}
		}
	}

}