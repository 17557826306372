//------ Course CTA - Section Styles ------//

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.course-cta {

            div.cta-block {
                padding: 0;
                border-radius: 0;
                border: none;
            }
    	}
    }

}