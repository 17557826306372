//------ Flatpickr Styles ------//

.flatpickr-months {

	.flatpickr-prev-month,
	.flatpickr-next-month {

		&:hover {

			svg {

				path {
					fill: $grey_5;
				}
			}
		}
	}
}

.flatpickr-day {

	&.today:not(.flatpickr-disabled) {
    	border-color: $grey_5;

    	&:focus,
    	&:hover {
    		border-color: $grey_5;
    		background: $grey_5;
    	}
	}

	&.endRange {
		background: $grey_5;
		border-color: $grey_5;

		&.inRange,
		&.nextMonthDay,
		&.prevMonthDay,
		&:focus,
		&:hover {
			background: $grey_5;
		    border-color: $grey_5;
		}
	}

	&.selected {
		background: $grey_5;
		border-color: $grey_5;

		&.inRange,
		&.nextMonthDay,
		&.prevMonthDay,
		&:focus,
		&:hover {
			background: $grey_5;
		    border-color: $grey_5;
		}
	}
	
	&.startRange {
		background: $grey_5;
		border-color: $grey_5;

		&.inRange,
		&.nextMonthDay,
		&.prevMonthDay,
		&:focus,
		&:hover {
		    background: $grey_5;
		    border-color: $grey_5;
		}
	}
}