//------ Default Banner - Section Styles ------//

section {

    &.default-banner {
        position: relative;

        div.text-wrapper {
            padding: 0 4em;

            div.text-wrapper__inner {
                max-width: 515px;

                h1 {
                    font-size: 3em;
                }

                p {

                    a {

                        &:not(.btn) {
                            transition: all 0.2s ease-in-out;
                            border-bottom: 1px solid;
                            font-family: $primary_600_font;
                            font-weight: 600;

                            .no-touchevents &:hover {
                                color: $grey_3;
                                border-color: $grey_3;
                            }
                        }
                    }

                    &.btn-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 15px;

                        & > a.btn {

                            &:nth-of-type(2n) {
                                margin-left: 0;

                                // .safari &,
                                // .ie & {
                                //     margin-left: 15px;
                                // }
                            }
                        }
                    }
                }

                ul, ol {

                    &:not(.menu) {
                        padding: 1em 0;
                        margin: 0 0 0 1.1em;
                    }
                }

                ol {

                    li {
                        margin-bottom: 1em;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &.text-only {

            &.field-1 {
                padding: 4em 0;
            }

            & + section.courses,
            & + section.teachers {
                padding-top: 0;
            }
        }

        &.has-illustration {
            min-height: 300px;

            &.field-1 {
                padding-top: calc(4em + 108px);

                .loaded & {
                    padding-top: calc(4em + var(--hh)); 
                }
            }

            &.illustration-1 {
                padding-bottom: calc(4em + 11px);
            }

            &.illustration-2 {
                padding-bottom: calc(4em + 13px);
            }

            &.illustration-4,
            &.illustration-5,
            &.illustration-6 {
                padding-bottom: calc(4em + 12px);
            }

            &.illustration-3 {
                padding-bottom: calc(4em + 81px);
            }

            &.illustration-7 {
                padding-bottom: calc(4em + 11px);
            }

            &.illustration-8 {
                padding-bottom: calc(4em + 61px);
            }

            &.illustration-9 {
                padding-bottom: calc(4em + 41px);
            }

            &.illustration-10 {
                padding-bottom: calc(4em + 13px);
            }

            &.illustration-11 {
                padding-bottom: calc(4em + 60px);
            }

            &.illustration-12 {
                padding-bottom: calc(4em + 30px);
            }

            &.illustration-13 {
                padding-bottom: calc(4em + 15px);
            }

            div.text-wrapper {
                position: relative;
                z-index: 1;

                div.text-wrapper__inner {
                    max-width: 550px;
                    padding-bottom: 4em;
                }
            }

            div.illustration {
                position: absolute;
                left: 0;
                bottom: 4em;
                width: 100%;
            }
        }

        &.has-media {
            min-height: 650px;

            div.text-wrapper {

                div.text-wrapper__inner {
                    max-width: unset;

                    h1, h2, p {
                        max-width: 515px;

                        &.btn-wrapper {
                            max-width: unset;
                        }

                        &.small {
                            max-width: unset;   
                        }
                    }
                }
            }

            div.media-wrapper {
                overflow: hidden;

                &.full-height {
                    height: 100%;
                    height: 100vh;

                    .iphone & {
                        height: 80%;
                        height: 80vh;
                    }

                    // .iphone & {
                    //     height: calc(80% - 115px);
                    //     height: calc(80vh - 115px);
                    // }
                }

                &.half-height {
                    height: calc(50% + 106px);
                    height: calc(50vh + 106px);

                    .loaded & {
                        height: calc(50% + var(--hh));
                        height: calc(50vh + var(--hh));
                    }
                }

                span {

                    &.overlay {
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-color: $black;
                        z-index: 2;
                    }
                }

                img, video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                video {
                    z-index: 1;
                }
            }

            div.text-wrapper {
                display: flex;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                padding: 0 4em;
                height: 100%;
                width: 100%;
                color: $white;
                z-index: 3;

                div.text-wrapper__inner {

                    p {
                        color: $white;

                        a {
                            
                            &:not(.btn) {

                                .no-touchevents &:hover {
                                    color: $white;
                                    border-color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

div.banner-logos {
    width: 100%;
    text-align: center;

    &.hide-mobile {
        padding: 2.5em 4%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;

        span {

            &.banner-logos__title {
                color: $white;
            }
        }

        div.banner-logos__list {
            flex-wrap: no-wrap;
            gap: 20px;

            // .safari &,
            // .ie & {

            //     div.logo {
            //         margin-right: 20px;

            //         &:last-of-type {
            //             margin-right: 0;
            //         }
            //     }
            // }
        }
    }

    &.hide-desktop {
        display: none;
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 2em 0 0 0;

        // .safari &,
        // .ie & {
            
        //     span {

        //         &.banner-logos__title {
        //             margin-bottom: -0.5em;
        //         }
        //     }
        // }

        div.banner-logos__list {
            flex-wrap: wrap;
            gap: 1em;

            // .safari &,
            // .ie & {

            //     div.logo {
            //         margin-top: 1em;
            //         margin-right: 1em;

            //         &:last-of-type {
            //             margin-right: 0;
            //         }
            //     }
            // }
        }
    }

    span {

        &.banner-logos__title {
            display: block;
            font-family: $primary_600_font;
            font-weight: 600;
            margin-bottom: 0.5em;
        }
    }

    div.banner-logos__list {
        display: flex;
        flex-direction: row;
        justify-content: center;

        div.logo {
            display: flex;
            align-items: center;
            width: auto;
            max-height: 25px;

            svg,
            img {
                width: auto;
                max-height: 25px;
            }
        }
    }
}

//------ 64em = 1024px ------//

@media screen and (max-width: 64em) {

    section {

        &.default-banner {

            &.has-illustration {

                div.text-wrapper {

                    div.text-wrapper__inner {
                        max-width: unset;

                        h1 + p {
                            max-width: unset;
                        }
                    }
                }

                div.illustration {
                    position: static;
                    left: unset;
                    bottom: unset;
                }
            }
        }
    }

}

//------ 56.25em = 900px ------//

@media screen and (max-width: 56.25em) {

    section {

        &.default-banner {
            position: relative;

            div.text-wrapper {
                padding: 0 4%;
            }
        }
    }

}

//------ 43.75em = 700px ------//

@media screen and (max-width: 43.75em) {

    section {

        &.default-banner {
            position: relative;

            div.text-wrapper {

                div.text-wrapper__inner {
                    padding-bottom: 2em;

                    h1 {

                        & + p {
                            max-width: 300px;
                        }
                    }

                    p {

                        &.btn-wrapper {

                            a.btn {
                                width: 100%;
                            }

                            & > a.btn {

                                &:nth-of-type(2n) {
                                    margin-top: 0;

                                    // .safari &,
                                    // .ie & {
                                    //     margin-top: 1em;
                                    //     margin-left: 0;
                                    // }
                                }
                            }
                        }
                    }
                }
            }

            &.text-only {

                &.field-1 {
                    padding: 2em 0;
                }

                div.text-wrapper {

                    div.text-wrapper__inner {
                        padding-bottom: 0;
                    }
                }
            }

            &.has-illustration {

                &.field-1 {
                    padding-top: calc(2em + 99px);

                    .loaded & {
                        padding-top: calc(2em + var(--hh)); 
                    }
                }

                &.illustration-1,
                &.illustration-2,
                &.illustration-3,
                &.illustration-4,
                &.illustration-5,
                &.illustration-6,
                &.illustration-7,
                &.illustration-8,
                &.illustration-9,
                &.illustration-10,
                &.illustration-11,
                &.illustration-12,
                &.illustration-13 {
                    padding-bottom: 2em;
                }

                &.illustration-2,
                &.illustration-3 {

                    div.illustration {
                        transform: translateX(-44%);
                    }
                }

                &.illustration-8,
                &.illustration-9 {

                    div.illustration {
                        transform: translateX(-45%);
                    }
                }

                &.illustration-10 {

                    div.illustration {
                        transform: translateX(-42%);
                    }
                }

                &.illustration-12,
                &.illustration-13 {

                    div.illustration {
                        transform: translateX(-43%);
                    }
                }

                div.text-wrapper {

                    div.text-wrapper__inner {
                        padding-bottom: 2em;
                    }
                }

                div.illustration {
                    width: 200%;
                    transform: translateX(-50%);
                }
            }

            &.has-media {

                &.iphone & {
                    max-height: 500px;
                }

                div.text-wrapper {
                    padding: 2em 4%;
                    align-items: flex-end;

                    div.text-wrapper__inner {
                        padding-bottom: 0;

                        h1 {
                            max-width: 300px;

                            & + p {
                                max-width: unset;
                            }
                        }
                    }
                }
            }
        }
    }

    div.banner-logos {

        &.hide-mobile {
            display: none;
        }

        &.hide-desktop {
            display: block;
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.default-banner {

            div.text-wrapper {

                div.text-wrapper__inner {

                    h1 {
                        font-size: 2em;
                    }
                }
            }

            &.has-media {

                div.text-wrapper {

                    div.text-wrapper__inner {

                        h1 {
                            font-size: 2em;
                        }
                    }
                }
            }
        }
    }

}

//------ Media Queries (Exact Match) ------//

@include breakpoint(ipad_portrait) {

    section {

        &.default-banner {

            &.has-media {

                div.media-wrapper {

                    &.full-height,
                    &.half-height {
                        max-height: 500px;
                    }
                }
            }
        }
    }

}

@include breakpoint(ipad_landscape) {

    section {

        &.default-banner {

            &.has-media {

                div.media-wrapper {

                    &.full-height,
                    &.half-height {
                        max-height: 600px;
                    }
                }
            }
        }
    }

}
