//------ Image/Text Blocks - Section Styles ------//

section {

    &.image-text-blocks {

        &.grey-3-bg,
        &.grey-4-bg,
        &.grey-5-bg {

            div.blocks {

                div.block {

                    div.block__content {
                        color: $white;

                        p {
                            color: $white; 

                            strong {
                                color: $white;
                            } 
                        }

                        a {

                            &:not(.btn) {
                                color: $white;

                                .no-touchevents &:hover {
                                    color: $white;
                                    border-color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }

        div.blocks {

            div.block {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-bottom: 4em;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &.block--left {

                    div.block__image {
                        order: 2;
                    }

                    div.block__content {
                        order: 1;
                        padding-right: 4em;
                    }
                }

                &.block--right {

                    div.block__image {
                        order: 1;
                    }

                    div.block__content {
                        order: 2;
                        padding-left: 4em;
                    }
                }

                div.block__image,
                div.block__content {
                    float: left;
                    width: 50%;
                }

                div.block__content {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;

                    h1 {
                        font-size: 2em;
                        line-height: 1.4em;
                    }
                    
                    a {
                            
                        &:not(.btn) {
                            transition: all 0.2s ease-in-out;
                            border-bottom: 1px solid;
                            font-family: $primary_600_font;
                            font-weight: 600;

                            .no-touchevents &:hover {
                                color: $grey_3;
                                border-color: $grey_3;
                            }
                        }

                        &.btn {
                            margin-top: 1em;
                        }
                    }

                    ul, ol {
                        padding: 1em 0 0 0;
                        margin: 0 0 0 1.1em;
                    }

                    ol {

                        li {
                            margin-bottom: 1em;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.image-text-blocks {

            div.blocks {

                div.block {
                    flex-direction: column;
                    margin-bottom: 2em;

                    &.block--left {

                        div.block__image {
                            order: 1;
                        }

                        div.block__content {
                            order: 2;
                            padding-right: 0;
                            padding-top: 1em;
                        }
                    }

                    &.block--right {

                        div.block__content {
                            padding-left: 0;
                            padding-top: 1em;
                        }
                    }

                    div.block__image,
                    div.block__content {
                        width: 100%;
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.image-text-blocks {

            div.blocks {

                div.block {

                    div.block__content {

                        h1 {
                            font-size: 1.8em;
                            line-height: 1.2em;
                        }

                        a {

                            &.btn {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

}