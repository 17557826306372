//------ Gallery - Section Styles ------//

section {

    &.gallery {

    	div.gallery-images {
    		
    		&.grid-container {

    			&.grid-one-fourth {
			        grid-column-gap: 0;
			        grid-row-gap: 0;
			    }
    		}

            div.image {
                position: relative;
                display: block;
                width: 100%;
                height: 0;
                padding-top: 100%;
                overflow: hidden;

                .ie & {
                    float: left;
                    width: 25%;
                    padding-top: 25%;
                }

                a {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;

                    .no-touchevents &:hover {

                        img {
                            height: 120%;
                            width: 120%;
                            margin-top: -10%;
                        }
                    }

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        transition: all 0.2s ease-in-out;
                    }
                }
            }
		}
	}
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

	section {

	    &.gallery {
            padding: 2em 0;

	    	div.gallery-images {

		    	&.grid-container {

	                &.grid-one-fourth {
	                    grid-template-columns: repeat(2, 1fr);
	                }
	            }
        	}
		}
	}

}