//------ Fonts ------//

@font-face {
	font-family: 'Athletics-Regular';
	src: url('../../dist/assets/webfonts/athletics/regular/Athletics-Regular.eot');
	src: url('../../dist/assets/webfonts/athletics/regular/Athletics-Regular.eot?#iefix') format('embedded-opentype'), url('../../dist/assets/webfonts/athletics/regular/Athletics-Regular.woff2') format('woff2'), url('../../dist/assets/webfonts/athletics/regular/Athletics-Regular.woff') format('woff'), url('../../dist/assets/webfonts/athletics/regular/Athletics-Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Athletics-ExtraBold';
	src: url('../../dist/assets/webfonts/athletics/extra-bold/Athletics-ExtraBold.eot');
	src: url('../../dist/assets/webfonts/athletics/extra-bold/Athletics-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../../dist/assets/webfonts/athletics/extra-bold/Athletics-ExtraBold.woff2') format('woff2'), url('../../dist/assets/webfonts/athletics/extra-bold/Athletics-ExtraBold.woff') format('woff'), url('../../dist/assets/webfonts/athletics/extra-bold/Athletics-ExtraBold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-Regular';
	src: url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular.eot');
	src: url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular.woff2') format('woff2'), url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular.woff') format('woff'), url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-Italic';
	src: url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular-Italic.eot');
	src: url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular-Italic.eot?#iefix') format('embedded-opentype'), url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular-Italic.woff2') format('woff2'), url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular-Italic.woff') format('woff'), url('../../dist/assets/webfonts/roboto/regular/Roboto-Regular-Italic.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-500';
	src: url('../../dist/assets/webfonts/roboto/500/Roboto-500.eot');
	src: url('../../dist/assets/webfonts/roboto/500/Roboto-500.eot?#iefix') format('embedded-opentype'), url('../../dist/assets/webfonts/roboto/500/Roboto-500.woff2') format('woff2'), url('../../dist/assets/webfonts/roboto/500/Roboto-500.woff') format('woff'), url('../../dist/assets/webfonts/roboto/500/Roboto-500.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-500-Italic';
	src: url('../../dist/assets/webfonts/roboto/500/Roboto-500-Italic.eot');
	src: url('../../dist/assets/webfonts/roboto/500/Roboto-500-Italic.eot?#iefix') format('embedded-opentype'), url('../../dist/assets/webfonts/roboto/500/Roboto-500-Italic.woff2') format('woff2'), url('../../dist/assets/webfonts/roboto/500/Roboto-500-Italic.woff') format('woff'), url('../../dist/assets/webfonts/roboto/500/Roboto-500-Italic.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-700';
	src: url('../../dist/assets/webfonts/roboto/700/Roboto-700.eot');
	src: url('../../dist/assets/webfonts/roboto/700/Roboto-700.eot?#iefix') format('embedded-opentype'), url('../../dist/assets/webfonts/roboto/700/Roboto-700.woff2') format('woff2'), url('../../dist/assets/webfonts/roboto/700/Roboto-700.woff') format('woff'), url('../../dist/assets/webfonts/roboto/700/Roboto-700.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto-700-Italic';
	src: url('../../dist/assets/webfonts/roboto/700/Roboto-700-Italic.eot');
	src: url('../../dist/assets/webfonts/roboto/700/Roboto-700-Italic.eot?#iefix') format('embedded-opentype'), url('../../dist/assets/webfonts/roboto/700/Roboto-700-Italic.woff2') format('woff2'), url('../../dist/assets/webfonts/roboto/700/Roboto-700-Italic.woff') format('woff'), url('../../dist/assets/webfonts/roboto/700/Roboto-700-Italic.ttf') format('truetype');
	font-display: swap;
}

//------ Font Family ------//

$primary_regular_font: 'Athletics-Regular', Helvetica, Arial, sans-serif;
$primary_regular_italic_font: 'Athletics-Regular', Helvetica, Arial, sans-serif;
$primary_600_font: 'Athletics-ExtraBold', Helvetica, Arial, sans-serif;
$primary_600_italic_font: 'Athletics-ExtraBold', Helvetica, Arial, sans-serif;
$roboto_regular_font: 'Roboto-Regular', Helvetica, Arial, sans-serif;
$roboto_regular_italic_font: 'Roboto-Italic', Helvetica, Arial, sans-serif;
$roboto_500_font: 'Roboto-500', Helvetica, Arial, sans-serif;
$roboto_500_italic_font: 'Roboto-500-Italic', Helvetica, Arial, sans-serif;
$roboto_700_font: 'Roboto-700', Helvetica, Arial, sans-serif;
$roboto_700_italic_font: 'Roboto-700-Italic', Helvetica, Arial, sans-serif;




