//------ Basic Content - Section Styles ------//

section {

    &.basic-content {
        overflow: visible;

        &.field-1 {
            padding: 4em 0;
        }

        &.grey-3-bg,
        &.grey-4-bg,
        &.grey-5-bg {
            color: $white;

            p {
                color: $white;

                strong {
                    color: $white;
                }
            }

            a:not(.btn) {
                color: $white;

                .no-touchevents &:hover {
                    color: $white;
                    border-color: $white;
                }
            }

            blockquote {
                border-color: $white;
                color: $white;

                p {
                    color: $white;
                }
            }
        }

        div.container {

            & > h2:first-child,
            & > h3:first-child {
                margin-top: 0;
            }

            &.content-blocks {

                &.two-columns {

                    div.block {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 8em;

                        &.white-bg,
                        &.grey-1-bg,
                        &.grey-2-bg,    
                        &.grey-3-bg,
                        &.grey-4-bg,
                        &.grey-5-bg {
                            padding: 4em;
                            border-radius: 20px;
                        }

                        &.grey-3-bg,
                        &.grey-4-bg,
                        &.grey-5-bg {

                            div.block__sidebar {
                                color: $white;

                                p {
                                    color: $white;
                                }

                                a:not(.btn) {
                                        
                                    .no-touchevents & {
                                        color: $white;
                                        border-color: $white;
                                    }
                                }
                            }

                            div.block__content {
                                color: $white;

                                p {
                                    color: $white;
                                }
                            }
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        &.block--left {

                            div.block__sidebar {
                                order: 1;
                                width: 30%;
                                padding-right: 4em;
                            }

                            div.block__content {
                                order: 2;
                                width: 70%;
                            }
                        }

                        &.block--right {

                            div.block__sidebar {
                                order: 2;
                                width: 30%;
                                padding-left: 4em;
                            }

                            div.block__content {
                                order: 1;
                                width: 70%;
                            }
                        }

                        div.block__sidebar {

                            div.sticky,
                            div.not-sticky {

                                & > h2,
                                & > h3,
                                & > h4,
                                & > p {

                                    &:first-child {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }

                        div.block__content {

                            & > h2,
                            & > h3,
                            & > h4,
                            & > p {
                                
                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }

            &.social-sharing {
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;

                nav {

                    &.social-menu {
                        background-color: $grey_1;
                        padding: 2em;
                        border-radius: 20px;

                        li {

                            a {
                                border-bottom: none !important;
                            }
                        }
                    }
                }
            }
        }

        h1 {
            margin-bottom: 0.5em;
        }

        a {

            &.btn {
                margin-top: 1em;
            }
            
            &:not(.btn) {
                transition: all 0.2s ease-in-out;
                border-bottom: 1px solid;
                font-family: $primary_600_font;

                .no-touchevents &:hover {
                    color: $grey_3;
                    border-color: $grey_3;
                }
            }
        }

    	img {
    		margin: 2em 0;
    	}

        div.iframe-wrapper {
            margin: 2em 0;
        }

    	h2, h3, h4 {
    		margin-top: 1em;
    	}

    	ul, ol {

            &:not(.menu) {
                padding: 1em 0 0 0;
                margin: 0 0 0 1.1em;
            }
        }

        ul {

            &:not(.menu) {
                list-style-type: none;

                &.blue-square {

                    li {

                        &:before {
                            content: '\25AA';
                            color: $blue;
                        }
                    }
                }

                li {

                    &:before {
                        content: '\2022';
                        color: $orange;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }

        ol {

            li {
                margin-bottom: 1em;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        div.two-columns {

            &.column {
                float: left;
                width: 49%;
                margin-right: 2%;

                & > h2,
                & > h3,
                & > h4,
                & > p {

                    &:first-child {
                        margin-top: 0;
                    }
                }

                &.end {
                    margin-right: 0;
                } 
            }
        }
    }
}

//------ 75em = 1200px ------//

// @media screen and (max-width: 75em) {

//     section {

//         &.basic-content {

//             nav {

//                 &.social-menu {

//                     ul {

//                         li {
//                             display: block;
//                             margin-right: 0;
//                             margin-bottom: 20px;
//                         }
//                     }
//                 }
//             }
//         }
//     }

// }

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.basic-content {
            overflow: hidden;

            div.container {

                &.content-blocks {

                    &.two-columns {

                        div.block {
                            flex-direction: column;
                            margin-bottom: 2em;

                            &.white-bg,
                            &.grey-1-bg,
                            &.grey-2-bg,    
                            &.grey-3-bg,
                            &.grey-4-bg,
                            &.grey-5-bg {
                                padding: 2em;
                            }

                            &.block--left {

                                div.block__sidebar {
                                    order: 1;
                                    width: 100%;
                                    padding-right: 0;
                                    margin-bottom: 1em;
                                }

                                div.block__content {
                                    order: 2;
                                    width: 100%;
                                }
                            }

                            &.block--right {

                                div.block__sidebar {
                                    order: 1;
                                    width: 100%;
                                    padding-left: 0;
                                    text-align: left;
                                    margin-bottom: 1em;
                                }

                                div.block__content {
                                    order: 2;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            // nav {

            //     &.social-menu {
            //         margin: 2em 0 0 0;
            //         text-align: center;

            //         ul {

            //             li {
            //                 display: inline-block;
            //                 margin-right: 20px;
            //                 margin-bottom: 0;

            //                 &:last-of-type {
            //                     margin-right: 0;
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.basic-content {

            &.field-1 {
                padding: 2em 0;
            }

            img {
                margin: 1em 0;
            }

            a {

                &.btn {
                    margin-top: 0;
                }
            }
        }
    }

}