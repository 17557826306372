//------ Instagram CTA - Section Styles ------//

#sb_instagram {

    #sbi_images {
        padding: 0px !important;
    }

    a.sbi_photo {
    	transition: all 0.2s ease-in-out;

    	.no-touchevents &:hover {
    		transform: scale(1.2);
    	}
    }
}

section {

    &.instagram-cta {

        div.instagram-wrapper {
            display: flex;
            flex-direction: row;

            div.instagram-wrapper__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                float: left;
                width: 40%; 
                padding: 3em 3em 3em 5%;

                div.text-wrapper {

                    p {

                        &.uppercase {
                            margin-top: 0;
                        }
                    }

                    a {

                        &:not(.btn) {
                            transition: all 0.2s ease-in-out;
                            border-bottom: 1px solid;
                            font-family: $primary_600_font;
                            font-weight: 600;
                        }

                        &.btn {
                            margin-top: 1em;
                        }
                    }
                }
            }

            div.instagram-wrapper__blocks {
                float: left;
                width: 60%;

                &.greyscale {
                    filter: grayscale(100%);
                }
            }
        }
    }
}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.instagram-cta {

            div.instagram-wrapper {
                flex-direction: column;

                div.instagram-wrapper__content {
                    width: 100%; 
                    margin: 0 auto;
                    padding: 3em;
                    text-align: center;

                    div.text-wrapper {

                    	br {
                    		display: none;
                    	}
                    }
                }

                div.instagram-wrapper__blocks {
                    width: 100%;
                    border-left: none;
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.instagram-cta {

            div.instagram-wrapper {

                div.instagram-wrapper__content {
                    padding: 2em;

                    div.text-wrapper {

                        a {

                            &.btn {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

}

//------ Media Queries (Exact Match) ------//

@include breakpoint(ipad_portrait) {

    section {

        &.instagram-cta {

            div.instagram-wrapper {
                flex-direction: column;

                div.instagram-wrapper__content {
                    width: 100%; 
                    margin: 0 auto;
                    padding: 3em;
                    text-align: center;
                }

                div.instagram-wrapper__blocks {
                    width: 100%;
                    border-left: none;
                }
            }
        }
    }

}