//------ 404 - Section Styles ------//

section {

    &.error404 {

    	div.container {

    		p {

    			a {
                    border-bottom: 1px solid;
                }
    		}
    	}
    }
}