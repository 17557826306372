//------ Header Styles ------//

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    will-change: transform;
    z-index: 999;

    .scrolled & {
        background-color: $white;
        box-shadow: 1px 0 6px 1px rgba(0, 0, 0, 0.10);

        div.header-wrapper {
            padding: 1em 4%;

            div.header-wrapper__left {
                    
                svg {
                    max-width: 150px;
                }
            }

            div.header-wrapper__right {

                div.hamburger-btn {

                    span.line {
                        background-color: $grey_5;
                    }
                }
            }
        }
    }

    .mobile-menu-open.scrolled &,
    .white-menu.scrolled & {

        div.header-wrapper {

            div.header-wrapper__left {
                    
                svg {

                    path {
                        fill: $grey_5;
                    }
                }
            }

            div.header-wrapper__right {

                div.hamburger-btn {

                    span.line {
                        background-color: $grey_5;
                    }
                }

                nav {

                    &.main-menu {

                        ul {

                            li {
                                color: $grey_5;
                                
                                a {

                                    svg {

                                        path {
                                            stroke: $grey_5;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    div.header-top {
        display: block;
        width: 100%;

        div.header-top__announcement {
            padding: 0.5em 2%;
            background-color: $orange;
            color: $white;
            
            p {
                margin: 0;
                line-height: 1.4em;
                text-align: center;
                color: $white;
            }
        }
    }

    div.header-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2em 4%;

        .ie & {
           padding: 0 4%; 
        }

        &.container {
            padding: 0;
        }

        div.header-wrapper__left {

            a {

                &.logo {
                    display: contents;
                }
            }
            
            svg {
                display: block;
                width: 100%;
                max-width: 200px;
                transition: all 0.2s ease-in-out;

                path {
                    fill: $grey_5;
                    transition: all 0.2s ease-in-out;
                }

                .white-menu & {

                    path {
                        fill: $white;
                    }
                }
            }
        }

        div.header-wrapper__right {
        	width: 100%;
        	text-align: right;

            .ie & {
               width: 88%;
            }

            nav {

                &.main-menu {

                    ul {
                        list-style-type: none;
                        margin: 0;
                        margin-left: 3em;
                        padding: 0;

                        .not-ie & {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: flex-end;
                            row-gap: 0px;
                            column-gap: 30px;
                        }

                        li {
                            position: relative;
                            display: inline-block;
                            font-family: $primary_600_font;
                            font-size: 0.9em;
                            @include uppercase-text;
                            color: $grey_5;

                            .safari &,
                            .ie & {
                                margin-left: 30px;
                            }

                            .white-menu & {
                                color: $white;
                            }

                            &.disable {

                                & > a {
                                    pointer-events: none;
                                }
                            }

                            a {
                                display: inline-flex;
                                align-items: center;
                                transition: all 0.2s ease-in-out;

                                .no-touchevents &:hover {
                                    color: $orange;

                                    svg {
                                        path {
                                            stroke: $orange;
                                        }
                                    }
                                }

                                svg {
                                    width: 15px;
                                    margin-left: 5px;
                                    margin-top: -4px;
                                    transition: all 0.2s ease-in-out;

                                    .ie & {
                                       height: 15px;
                                    }

                                    .touchevents & {
                                        display: none;
                                    }

                                    path {
                                        transition: all 0.2s ease-in-out;
                                        stroke: $grey_5;
                                    }

                                    .white-menu & {

                                        path {
                                            stroke: $white;
                                        }
                                    }
                                }
                            }

                            &.blue {

                                & > a {

                                    .no-touchevents &:hover {
                                        color: $blue;

                                        svg {
                                            path {
                                                stroke: $blue;
                                            }
                                        }
                                    }
                                }

                                ul.sub-menu {
                        
                                    li {

                                        a {

                                            .no-touchevents &:hover {
                                                color: $blue;
                                            }
                                        }
                                    }
                                }
                            }

                            &.green {

                                & > a {

                                    .no-touchevents &:hover {
                                        color: $green;

                                        svg {
                                            path {
                                                stroke: $green;
                                            }
                                        }
                                    }
                                }

                                ul.sub-menu {
                        
                                    li {

                                        a {

                                            .no-touchevents &:hover {
                                                color: $green;
                                            }
                                        }
                                    }
                                }
                            }

                            &.purple {

                                & > a {

                                    .no-touchevents &:hover {
                                        color: $purple;

                                        svg {
                                            path {
                                                stroke: $purple;
                                            }
                                        }
                                    }
                                }

                                ul.sub-menu {
                        
                                    li {

                                        a {

                                            .no-touchevents &:hover {
                                                color: $purple;
                                            }
                                        }
                                    }
                                }
                            }

                            &.mauve {

                                & > a {

                                    .no-touchevents &:hover {
                                        color: $mauve;

                                        svg {
                                            path {
                                                stroke: $mauve;
                                            }
                                        }
                                    }
                                }

                                ul.sub-menu {
                        
                                    li {

                                        a {

                                            .no-touchevents &:hover {
                                                color: $mauve;
                                            }
                                        }
                                    }
                                }
                            }

                            ul.sub-menu {
                                visibility: hidden;
                                opacity: 0;
                                background-color: $white;
                                width: 410px;
                                text-align: left;
                                position: absolute;
                                top: 60px;
                                right: 50%;
                                transform: translateX(50%);
                                padding: 20px;
                                box-shadow: 1px 6px 50px 1px rgba(0, 0, 0, 0.1);
                                transition: all 0.2s ease-in-out;
                                z-index: 999;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: -8px;
                                    right: 0;
                                    left: 0;
                                    width: 0;
                                    height: 0;
                                    display: inline-block;
                                    margin: auto;
                                    border-style: solid;
                                    border-width: 0 8.5px 9px 8.5px;
                                    border-color: transparent transparent $white transparent;
                                }
                        
                                li {
                                    display: block;
                                    width: 100%;
                                    color: $grey_5;
                                    line-height: 1.4em;
                                    padding: 8px 0;
                                    border-bottom: 1px solid $grey_2;
                                    transition: all ease 0.2s;

                                    &:first-child {
                                        padding-top: 0;
                                    }

                                    &:last-child {
                                        padding-bottom: 0;
                                        border-bottom: none;
                                    }

                                    &.menu-item-has-children {

                                        ul.sub-menu { display: none; }
                                    }

                                    a {
                                        display: block;

                                        .no-touchevents &:hover {
                                            color: $orange;
                                        }
                                    }

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }

                            .no-touchevents &.menu-item-has-children {

                                &:hover {

                                    & > a {

                                        svg {
                                            transform: scaleY(-1);
                                        }
                                    }

                                    ul.sub-menu {
                                        visibility: visible;
                                        opacity: 1;
                                        z-index: 999;
                                        top: 35px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            div.hamburger-btn {
                display: none;
                position: relative;
                width: 25px;
                cursor: pointer;

                span.line {
                    width: 100%;
                    height: 2px;
                    background-color: $grey_5;
                    display: block;
                    margin: 6px 0;
                    transition: all ease 0.3s;

                    .white-menu & {
                        background-color: $white;
                    }

                    .mobile-menu-open & {
                        background-color: $white;
                    }
                }

                .mobile-menu-open & {

                    span.line {

                        &.top {
                            transform: translateY(7px) rotate(-45deg);
                        }

                        &.middle {
                            width: 1px;
                            background: transparentize($white, 1);
                        }

                        &.bottom {
                            transform: translateY(-9px) rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    header {

        div.header-top {

            div.header-top__announcement {
                
                p {
                    font-size: 0.9em;
                }
            }
        }
    }

}

//------ Mobile Menu Styles ------//

div.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 325px;
    background-color: $grey_5;
    transform: translateX(101%);
    transition: all 0.3s ease-in-out;
    will-change: transform;
    overflow-y: scroll;
    z-index: 999;

    .mobile-menu-open & {
        transform: translateX(0);
    }

    div.mobile-menu__header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        height: 135px;
        border-bottom: 1px solid $white;
        
        svg {
            width: 100%;
            max-width: 200px;

            path {
                fill: $white;
            }
        }
    }

    div.mobile-menu__wrapper {
        overflow-y: scroll;
        height: calc(100% - 135px);
        height: calc(100vh - 135px);

        nav {

            &.main-menu {

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    li {
                        display: block;
                        font-size: 0.9em;
                        border-bottom: 1px solid $white;

                        a {
                            display: block;
                            padding: 15px;
                            font-family: $primary_600_font;
                            line-height: 1.4em;
                            @include uppercase-text;
                            color: $white;
                        }

                        &.disable {

                            & > a {
                                pointer-events: none;
                            }
                        }

                        &.menu-item-has-children {
                            position: relative;

                            & > a {
                                padding: 15px 75px 15px 15px;
                            }

                            span.mobile-expand {
                                background-color: $white;
                                display: inline-block;
                                height: 51px;
                                padding: 20px;
                                position: absolute;
                                right: 0;
                                top: 0;
                                cursor: pointer;

                                &.open {

                                    svg {
                                        transform: scaleY(-1);
                                    }
                                }

                                svg {
                                    width: 15px;
                                    margin-top: 1px;
                                    transition: all 0.2s ease-in-out;
                                }
                            }
                        }

                        nav {

                            &.sub-menu {
                                overflow-y: hidden;
                                max-height: 0;
                                transition: all 0.5s ease-in-out;

                                &.open {
                                    max-height: 500px; /* approximate max height */
                                }

                                ul {

                                    li {
                                        border-bottom: 1px solid $grey_5;
                                        background-color: $white;

                                        &.menu-item-has-children {

                                            ul.sub-menu { display: none; }
                                        }

                                        &:last-child {
                                            border-bottom: none;
                                        }

                                        a {
                                            padding: 15px 15px 15px 25px;
                                            color: $grey_5;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.social-menu {
                margin: 2em 0;
                text-align: center;

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    li {
                        display: inline-block;
                        margin-right: 1em;

                        a {

                            .no-touchevents &:hover {

                                svg {

                                    path {
                                        fill: $grey_5;
                                    }
                                }
                            } 

                            svg {
                                width: 20px;

                                path {
                                    transition: all 0.2s ease-in-out;
                                }
                            }
                        }

                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

//------ 85.9375em = 1375px ------//

@media screen and (max-width: 85.9375em) {

    header {

        div.header-wrapper {

            div.header-wrapper__right {

                nav {

                    &.main-menu {
                        display: none;
                    }
                }

                div.hamburger-btn {
                    display: block;
                    float: right;
                }
            }
        }
    }

}