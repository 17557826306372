//------ Newsletter CTA - Section Styles ------//

section {

    &.newsletter-cta {
        position: relative;

        div.cta-wrapper {

            div.cta-wrapper__content {
                padding-bottom: 4em;

                div.text-wrapper {
                    position: relative;
                    max-width: 700px;
                    z-index: 1;

                    p {

                        &.uppercase {
                            margin-top: 0;
                        }
                    }

                    a {

                        &:not(.btn) {
                            transition: all 0.2s ease-in-out;
                            border-bottom: 1px solid;
                            font-family: $primary_600_font;
                            font-weight: 600;

                            .no-touchevents &:hover {
                                color: $grey_3;
                                border-color: $grey_3;
                            }
                        }

                        &.btn {
                            margin-top: 1em;
                        }
                    }

                    form {
                        margin-top: 3em;

                        button {

                            &.subscribe-btn {
                                min-width: 160px;
                            }
                        }
                    }
                }
            }

            div.cta-wrapper__illustration {
                position: absolute;
                left: 0;
                bottom: 8em;
                width: 100%;
            }
        }
    }
}

//------ 64em = 1024px ------//

@media screen and (max-width: 64em) {

    section {

        &.newsletter-cta {

            div.cta-wrapper {

                div.cta-wrapper__content {
                    padding-bottom: 2em;
                }

                div.cta-wrapper__illustration {
                    position: static;
                    left: unset;
                    bottom: unset;
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.newsletter-cta {

            div.cta-wrapper {

                div.cta-wrapper__content {
                    padding-bottom: 1em;

                    div.text-wrapper {

                        br {
                            display: none;
                        }

                        a {

                            &.btn {
                                margin-top: 0;
                            }
                        }

                        form {
                            margin-top: 1em;

                            button.subscribe-btn {
                                margin-top: 1em;
                                width: fit-content;
                            }
                        }
                    }
                }

                div.cta-wrapper__illustration {
                    width: 200%;
                    transform: translateX(-50%);
                }
            }
        }
    }

}