//------ Colours ------//

$white: #FFFFFF;
$black: #000000;

$light_grey: #F8F9FA;
$grey_alt: #9FA1A5;
$grey_1: #F5F0F0;
$grey_2: #E6E1E1;
$grey_3: #C3BEBE;
$grey_4: #949191;
$grey_5: #595963;

$orange: #FA8770;
$blue: #30C8E6;
$blue_1: #017698;
$green: #4ECCB9;
$green_1: #5AC169;
$purple: #BE55CD;
$purple_1: #8952FF;
$mauve: #9383DD;