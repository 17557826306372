//------ Subscription Blocks - Section Styles ------//

section {

    &.subscription-blocks  {

        div.intro-wrapper {
            color: $white;
        }

        div.subscription-blocks {
            display: flex;
            flex-direction: row;
            gap: 2em;
            
            div.subcription {
                position: relative;
                background-color: $white;
                padding: 4em;
                border-radius: 10px;
                text-align: center;
                overflow: hidden;

                div.subcription__price {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                span {

                    &.most-popular {
                        display: block;
                        position: absolute;
                        right: -48px;
                        top: 38px;
                        background-color: $orange;
                        color: $white;
                        font-family: $primary_600_font;
                        font-weight: 600;
                        font-size: 0.75em;
                        line-height: 1em;
                        @include uppercase-text;
                        transform: rotate(45deg);
                        padding: 10px;
                        width: 200px;
                    }

                    &.duration {
                        display: block;
                        @include uppercase-text;
                        font-family: $primary_600_font;
                        font-weight: 600;
                        line-height: 1.2em;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 0.75em;
                    }

                    &.price {
                        display: block;
                        color: $orange;
                        font-family: $primary_600_font;
                        font-weight: 600;
                        font-size: 1.2em;
                        line-height: 1.4em;
                        margin: 1em 0;
                    }

                    &.terms {
                        display: block;
                        margin-bottom: 2em;
                        font-size: 0.85em;
                        line-height: 1.4em;
                    }
                }
            }
        }

        div.gift-subscription-blocks {
            display: flex;
            flex-direction: row;
            background-color: $white;
            border-radius: 10px;
            margin-top: 2em;

            div.block {
                width: 50%;

                &.image {
                    position: relative;
                    overflow: hidden;

                    div.image-wrapper {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        min-height: 300px;
                        border-radius: 10px 0px 0px 10px;
                        overflow: hidden;

                        &:before {
                            content: '';
                            background-color: $black;
                            opacity: 0.4;
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            z-index: 1;
                        }

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }

                    div.text-wrapper {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: $white;
                        text-align: center;
                        width: 100%;
                        padding: 2em;
                        max-width: 400px;
                        z-index: 2;

                        svg {
                            width: 30px;
                            height: auto;
                            margin-bottom: 0.5em;
                        }
                    }
                }

                &.subscriptions {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 4em;

                    div.gift-subscription {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        min-width: fit-content;
                        row-gap: 10px;
                        column-gap: 20px;
                        border-bottom: 1px solid $grey_1;
                        margin-bottom: 1em;
                        padding-bottom: 1em;

                        &:last-of-type {
                            border-bottom: none;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }

                        span {
                            font-family: $primary_600_font;
                            font-weight: 600;
                            @include uppercase-text;

                            &.duration {
                                min-width: 115px;
                            }
                        }
                    }
                }
            }
        }
    }
}

//------ 75em = 1200px ------//

@media only screen and (max-width: 75em) {

    section {

        &.subscription-blocks  {

            div.subscription-blocks {
                
                div.subcription {
                    padding: 4em 2em;
                }
            }

            div.gift-subscription-blocks {

                div.block {

                    &.subscriptions {
                        padding: 2em;
                    }
                }
            }
        }
    }

}

//------ 62.5em = 1000px ------//

@media only screen and (max-width: 62.5em) {
    
    section {

        &.subscription-blocks  {

            div.subscription-blocks {
                flex-direction: column;

                div.subcription {
                    padding: 2em;
                }
            }

            div.gift-subscription-blocks {
                flex-direction: column;

                div.block {
                    width: 100%;

                    &.image {

                        div.image-wrapper {
                            border-radius: 10px 10px 0px 0px;
                        }
                    }
                }
            }
        }
    }

}


//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.subscription-blocks  {

            div.subscription-blocks {

                div.subcription {

                    br {
                        display: none;
                    }
                    
                    span {

                        &.duration {
                            max-width: 200px;
                        }
                    }
                }
            }

            div.gift-subscription-blocks {

                div.block {

                    &.subscriptions {

                        div.gift-subscription {
                            justify-content: center;

                            span {

                                &.duration {
                                    min-width: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}