//------ Global Styles ------//

body {
    font-family: $primary_regular_font;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.6em;
    color: $grey_5;
    background-color: $white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    &.page-template-page-landing,
    &.page-template-page-pregnahub,
    &.white-menu,
    &.illustration-banner {

        main {
            margin-top: 0;
        }
    }

    &.mobile-menu-open {

        header,
        main,
        footer {
            transform: translateX(-325px);
        }

        div.mask {
            opacity: 0.6;
            visibility: visible;
        }
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*:focus { outline: 0; }

main {
    margin-top: 105px;
    will-change: transform;
    transition: all 0.3s ease-in-out;

    .loaded & {
        margin-top: var(--hh); 
    }
}

iframe {
    vertical-align: top;

    &[src*="forms.monday.com"] {
        width: 100% !important;
        min-height: 500px;
    }
}

video {
    vertical-align: top;

    &.greyscale {
        filter: grayscale(100%);
    }
}

svg {
    vertical-align: top;
}

div.mask {
    position: fixed;
    background-color: $black;
    opacity: 0;
    visibility: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: all 0.2s ease-in-out;
    z-index: 998;
}

div.wp-caption {
    position: relative;
    width: 100% !important;
    margin: 2em 0;

    &:last-of-type {
        margin-bottom: 0;
    }

    img {
        margin: 0 !important;
        width: 100%;
        height: auto;
    }

    p {

        &.wp-caption-text {
            margin: 0;
            padding: 2em;
            line-height: 1.4em;
            color: $white;
            font-family: $primary_600_italic_font;
            font-style: italic;
            font-weight: 600;
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
        }
    }
}

img {
    vertical-align: top;
    max-width: 100%;
    height: auto;
    opacity: 0;
    visibility: hidden;

    &.greyscale {
        filter: grayscale(100%);
    }

    &.alignnone {
        display: block;
    }

    &.aligncenter {
        display: block;
        margin: 2em auto !important;
        text-align: center;
    }

    &.alignleft {
        float: left;
        margin: 0 2em 0 0 !important;
    }

    &.alignright {
        float: right;
        margin: 0 0 0 2em !important;
    }

    &.no-margin {
        margin: 0 !important;
    }

    &[src] {
        opacity: 1;
        visibility: visible;
    }
}

p {
    margin: 1em 0 0 0;

    &.large {
        font-size: 1.2em;
        line-height: 1.4em;
    }

    &.small {
        font-size: 0.85em;
        line-height: 1.4em;
    }

    &.no-results {
        margin: 0;
        font-family: $primary_600_font;
        @include uppercase-text;
        text-align: center;

        &.font-roboto {
            font-family: $roboto_regular_font;
        }

        &.lowercase {
            letter-spacing: 0;
            text-transform: none;
        }

        &.black {
            color: $black;
        }
    }

    & > a.btn {

        &:nth-of-type(2n) {
            margin-left: 15px;
        }
    } 
}

ul, ol {

    &.large {

        li {
            font-size: 1.2em;
            line-height: 1.4em;
        }
    }
}

div#hidden-gallery {

    &.hidden {
        display: none;
    }
}

div.no-results {
    margin: auto auto 4em auto;
    max-width: 800px;

    &.font-roboto {
        font-family: $roboto_regular_font;
    }

    &.lowercase {

        &.black {

            p {

                &.uppercase {
                    color: $black;
                }
            }

            a {

                &.outline-orange {
                    font-family: $roboto_700_font;
                    font-weight: 700;
                    letter-spacing: 0;
                    text-transform: none;
                    border: none;
                    color: $white;
                    background-color: $orange;
                    border-radius: 5px;

                    svg {

                        path {
                            fill: $white;
                        }
                    }

                    .no-touchevents &:hover {
                        background-color: lighten($orange, 10%);
                    }
                }
            }
        }
    }
    
    div.no-results__content {
        text-align: center;

        p {

            &.uppercase {

                &:first-of-type {
                    margin-top: 0;
                }
            }

            a {

                &.btn {
                    margin-top: 1em;
                }

                &:not(.btn) {
                    transition: all 0.2s ease-in-out;
                    border-bottom: 1px solid;
                    font-family: $primary_600_font;
                    font-weight: 600;

                    .no-touchevents &:hover {
                        color: $grey_3;
                        border-color: $grey_3;
                    }
                }
            }
        }

        div.monday-form {
            margin-top: 2em;
        }
    }

    div.no-results__form {
        margin-top: 2em;
    }
}

span {

    &.ajax-loader {
        width: 18px;
        height: 18px;
        clear: both;
        display: inline-block;
        margin: 0 0 0 10px;
        text-indent: -9999em;
        border-top: 3px solid $grey_2;
        border-right: 3px solid $grey_2;
        border-bottom: 3px solid $grey_2;
        border-left: 3px solid $grey_5;
        animation: rotation 1s infinite linear;
        border-radius: 50%;
        vertical-align: middle;
    }

    &.error {
        display: block;
        font-family: $primary_regular_italic_font;
        font-style: italic;
        margin-top: 0.25em;
        color: #721c24;
    }

    &.alert {
        display: block;
        font-family: $primary_regular_italic_font;
        font-style: italic;
        padding: 0.5em;
        line-height: 1.4em;
        margin-top: 1em;
        width: 100%;

        a {
            border-bottom: 1px solid;
        }

        &.success {
            color: #155724;
            background-color: #d4edda;
            border-color: #c3e6cb;
        }

        &.danger {
            color: #721c24;
            background-color: #f8d7da;
            border-color: #f5c6cb;
        }
    }

    &.tooltip {
        opacity: 0;
        visibility: hidden;
        width: 150px;
        background-color: $grey_5;
        color: $white;
        padding: 1em;
        line-height: 1em;
        text-align: center;
        position: absolute;
        bottom: 130%;
        left: 50%;
        margin-left: -70px;
        transition: all 0.2s ease-in-out;
        z-index: 1;

        &.font-roboto {
            font-family: $roboto_regular_font;
            font-weight: 400;
        }

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -10px;
            border-width: 5px;
            border-style: solid;
            border-color: $grey_5 transparent transparent transparent;
        }
    }
}

span, time, p {

    &.category,
    &.read-more,
    &.author,
    &.date,
    &.teacher,
    &.location,
    &.format {
        font-family: $primary_600_font;
        @include uppercase-text;
        display: block;
        font-size: 0.9em;
        line-height: 1.4em;
        margin-bottom: 0.5em;
    }

    &.uppercase {
        font-family: $primary_600_font;
        @include uppercase-text;
        font-size: 0.9em;
        line-height: 1.6em;

        img {
            height: 35px;
            width: auto;
            margin-right: 15px;
        }
    }
}

i, em {
   font-family: $primary_regular_italic_font;
   font-style: normal;
}

b, strong {
    font-family: $primary_600_font;
    font-weight: 400;
}

hr {
    border: 0;
    height: 1px;
    background-color: $grey_5;
    margin: 2em 0;
}

blockquote {
    margin: 1em 0;
    position: relative;
    display: block;
    font-family: $primary_regular_italic_font;
    font-style: italic;
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.4em;
    padding-left: 1em;
    border-left: 2px solid $grey_5;
    color: $grey_5;

    p {
        color: $grey_5;
    }
}

a {
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    color: inherit;

    &:hover, &:active, &:focus {
        outline: 0;
    }
}

button {
    font-family: $primary_regular_font;
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
}

span, label {

    &.sr-only {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;
    }
}

span, a, button {

    &.disabled {
        pointer-events: none;
    }

    &.btn {
        display: inline-block;
        padding: 0.45em 2em;
        padding-top: calc(0.45em + 3px);
        font-family: $primary_600_font;
        font-size: 0.9em;
        @include uppercase-text;
        border-radius: 50px;
        background-color: transparent;
        border: 1px solid;
        color: inherit;
        white-space: nowrap;
        text-align: center;
        transition: all 0.2s ease-in-out;

        svg {
            margin-left: 10px;
            margin-top: -5px;
            width: 15px;

            path,
            circle {
                transition: all 0.2s ease-in-out;
            }
        }

        &.font-roboto-700 {
            font-family: $roboto_700_font;
            font-weight: 700;
        }

        &.lowercase {
            letter-spacing: 0;
            text-transform: none;
        }

        &.rounded {
            border-radius: 5px;
        }

        &.play-btn {
            display: inline-flex;
            align-items: center;
            margin: 2em 0 0 0;

            svg {
                width: 10px;
            }
        }

        &.gallery-btn {
            display: inline-flex;
            align-items: center;
            padding: 0.45em 1em;

            svg {
                width: 15px;
                margin-left: 0;
                margin-top: -4px;
                margin-right: 10px;
            }
        }

        &.large {
            padding: 0.5em 1em;
            white-space: unset;
        }

        &.solid-light-grey {
            border: none;
            background-color: $light_grey;
            color: $black;

            svg {

                path {
                    fill: $black;
                }
            }

            .no-touchevents &:hover {
                background-color: $black;
                color: $white;

                svg {

                    path {
                        fill: $white;
                    }
                }
            }
        }

        &.solid-grey-5 {
            border: none;
            background-color: $grey_5;
            color: $white;

            svg {

                path {
                    fill: $white;
                }
            }

            .no-touchevents &:hover {
                background-color: $grey_3;
            }
        }

        &.solid-white {
            border: none;
            background-color: $white;
            color: $black;

            svg {

                path,
                circle {
                    fill: $black;
                }
            }

            .no-touchevents &:hover {
                background-color: $black;
                color: $white;

                svg {

                    path,
                    circle {
                        fill: $white;
                    }
                }
            }
        }

        &.solid-orange {
            border: none;
            background-color: $orange;
            color: $white;

            svg {

                path {
                    fill: $white;
                }
            }

            .no-touchevents &:hover {
                background-color: lighten($orange, 10%);
            }
        }

        &.solid-blue {
            border: none;
            background-color: $blue;
            color: $white;

            svg {

                path {
                    fill: $white;
                }
            }

            .no-touchevents &:hover {
                background-color: lighten($blue, 10%);
            }
        }

        &.solid-green {
            border: none;
            background-color: $green;
            color: $white;

            svg {

                path {
                    fill: $white;
                }
            }

            .no-touchevents &:hover {
                background-color: lighten($green, 10%);
            }
        }

        &.solid-purple {
            border: none;
            background-color: $purple;
            color: $white;

            svg {

                path {
                    fill: $white;
                }
            }

            .no-touchevents &:hover {
                background-color: lighten($purple, 10%);
            }
        }

        &.solid-mauve {
            border: none;
            background-color: $mauve;
            color: $white;

            svg {

                path {
                    fill: $white;
                }
            }

            .no-touchevents &:hover {
                background-color: lighten($mauve, 10%);
            }
        }

        &.outline-grey-5 {
            color: $grey_5;

            svg {

                path {
                    fill: $grey_5;
                }
            }

            .no-touchevents &:hover {
                border-color: $grey_5;
                background-color: $grey_5;
                color: $white;

                svg {

                    path {
                        fill: $white;
                    }
                }
            }
        }

        &.outline-black {
            color: $black;

            svg {

                path {
                    fill: $black;
                }
            }

            .no-touchevents &:hover {
                border-color: $black;
                background-color: $black;
                color: $white;

                svg {

                    path {
                        fill: $white;
                    }
                }
            }
        }

        &.outline-white {
            color: $white;

            svg {

                path {
                    fill: $white;
                }
            }

            .no-touchevents &:hover {
                border-color: $white;
                background-color: $white;
                color: $grey_5;

                svg {

                    path {
                        fill: $grey_5;
                    }
                }
            }
        }

        &.outline-orange {
            color: $orange;

            svg {

                path {
                    fill: $orange;
                }
            }

            .no-touchevents &:hover {
                border-color: $orange;
                background-color: $orange;
                color: $white;

                svg {

                    path {
                        fill: $white;
                    }
                }
            }
        }

        &.outline-blue {
            color: $blue;

            svg {

                path {
                    fill: $blue;
                }
            }

            .no-touchevents &:hover {
                border-color: $blue;
                background-color: $blue;
                color: $white;

                svg {

                    path {
                        fill: $white;
                    }
                }
            }
        }

        &.outline-green {
            color: $green;

            svg {

                path {
                    fill: $green;
                }
            }

            .no-touchevents &:hover {
                border-color: $green;
                background-color: $green;
                color: $white;

                svg {

                    path {
                        fill: $white;
                    }
                }
            }
        }

        &.outline-purple {
            color: $purple;

            svg {

                path {
                    fill: $purple;
                }
            }

            .no-touchevents &:hover {
                border-color: $purple;
                background-color: $purple;
                color: $white;

                svg {

                    path {
                        fill: $white;
                    }
                }
            }
        }

        &.outline-mauve {
            color: $mauve;

            svg {

                path {
                    fill: $mauve;
                }
            }

            .no-touchevents &:hover {
                border-color: $mauve;
                background-color: $mauve;
                color: $white;

                svg {

                    path {
                        fill: $white;
                    }
                }
            }
        }
    }

    &.see-more-btn {
        display: inline-block;
        line-height: 1.4em;
        border-bottom: 1px solid;
        font-size: 0.9em;

        &.open {

            svg {
                transform: scaleY(-1);
            }
        }

        span {

            &.text {
                font-family: $primary_600_font;
                font-weight: 600;
                @include uppercase-text;
            }
        }

        svg {
            margin-left: 10px;
            margin-top: 4px;
            width: 15px;
            transition: all 0.2s ease-in-out;
        }
    }
}

div.cta-block {
    display: block;
    width: 100%;
    padding: 2em;
    border-radius: 10px;
    border: 1px solid $grey_2;
    text-align: center;

    span {
        display: block;

        &.heading {
            font-family: $roboto_regular_font;
            font-weight: 400;
            font-size: 1.8em;
            line-height: 1.4em;
            color: $black;
            margin-bottom: 0.5em;
        }

        &.pricing {
            font-family: $roboto_700_font;
            font-weight: 700; 
            font-size: 1.2em; 
            color: $black;
            margin-bottom: 1em; 
        }
    }

    a.btn {
        width: 100%;
        max-width: 275px;
    }
}

div.links {
    margin-top: 1em;
    width: 100%;
    max-width: 485px;

    a {
        border-bottom: 1px solid;
        transition: all 0.2s ease-in-out;

        .no-touchevents &:hover {
            color: $black;
            border-color: $black;
        }
    }
}

div.course-schedule {
    padding: 2em;
    margin-bottom: 2em;

    &.grey-3-bg,
    &.grey-4-bg,
    &.grey-5-bg {
        color: $white;

        div.course-schedule__dates {

            div.date {
                color: $white;

                span {

                    &.date,
                    &.time {
                        color: $white;
                    }
                }
            }
        }
    }

    h2 {

        &.course-schedule__title {
            margin-top: 0;
        }
    }

    div.course-schedule__dates {
        margin-top: 1em;

        div.date {
            border-top: 1px solid;
            padding: 1em 0;

            &:last-of-type {
                padding-bottom: 0;
            }

            h3 {
                margin-top: 0;
                margin-bottom: 0.25em;
            }

            span {

                &.date {
                    margin-bottom: 0;
                }

                &.date,
                &.time, {
                    display: block;
                    @include uppercase-text;
                    font-family: $primary_600_font;
                    font-size: 0.9em;
                    line-height: 1.6em;
                }
            }
        }
    }
}

div#afterpay-clearpay-message {
    background-color: $grey_5;
    font-family: $primary_600_font;
    color: $white;
    padding: 10px 20px;
    border-radius: 10px;
    width: fit-content;
    max-width: 485px;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    margin-top: 2em;

    &:empty {
        opacity: 0;
    }

    p {

        &.AfterpayMessage {
            margin: 0 !important;
        }
    }

    span {

        &.AfterpayMessage-logo {
            margin: 0 !important;
            padding-left: 5px;
        }
    }

    a {

        &.AfterpayMessage-learnMore {
            border-bottom: none;
            padding-left: 5px;
        }
    }
}

div#klarna-message {
    width: 100%;
    max-width: 485px;
    margin-top: 1em;

    & + div#afterpay-clearpay-message {
        margin-top: 1em;
    }
}

div.trustpilot-widget {

    &.slider {
        margin-top: 2em;
    }

    &.rating {
        padding: 0.5em;
        border-radius: 10px;
        width: fit-content;
        background-color: $grey_5;
        margin: 1em auto 0 auto;

        iframe {
            margin-top: 4px;
        }
    }
}

nav {

    &.social-menu {

        &.grey-5-menu {

            ul {

                li {
                    border-color: $grey_5;

                    .no-touchevents &:hover {
                        background-color: $grey_5;

                        svg {

                            path {
                                fill: $white;
                            }
                        }
                    }

                    svg {

                        path {
                            fill: $grey_5;
                        }
                    }
                }
            }
        }

        span {

            &.title {
                display: block;
                @include uppercase-text;
                font-family: $primary_600_font;
                margin-bottom: 0.5em;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin-right: 20px;
                width: 50px;
                height: 50px;
                font-size: 0.9em;
                border: 1px solid $white;
                border-radius: 50%;
                transition: all 0.2s ease-in-out;

                &.menu-item-has-children {

                    ul.sub-menu { display: none; }
                }

                .no-touchevents &:hover {
                    background-color: $white;

                    svg {

                        path {
                            fill: $grey_5;
                        }
                    }
                }

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                }

                svg {
                    width: 20px;

                    path {
                        fill: $white;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}

h1 { @include heading-1; }
h2 { @include heading-2; }
h3 { @include heading-3; }
h4 { @include heading-4; }

.sticky {
    position: sticky;
    top: calc(var(--hh) + 1em);
    left: 0;
}

.flex-center-h {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.centered {
    text-align: center;
}

.green-bg {
    background-color: $green;
}

.orange-bg {
    background-color: $orange;
}

.light-grey-bg {
    background-color: $light_grey;
}

.grey-1-bg {
    background-color: $grey_1;
}

.grey-2-bg {
    background-color: $grey_2;
}

.grey-3-bg {
    background-color: $grey_3;
}

.grey-4-bg {
    background-color: $grey_4;
}

.grey-5-bg {
    background-color: $grey_5;
}

.black-bg {
    background-color: $black;
}

.white-bg {
    background-color: $white;
}

.orange-txt {
    color: $orange;
}

.blue-txt {
    color: $orange;
}

.green-txt {
    color: $green;
}

.purple-txt {
    color: $purple;
}

.mauve-txt {
    color: $mauve;
}

div.table-wrapper {
    width: 100%;
    overflow: auto;
    margin: 1em 0;
    padding-bottom: 0.5em;

    &::-webkit-scrollbar {
        height: 1px;
    }
     
    &::-webkit-scrollbar-thumb {
        background-color: $grey_5;
        outline: 1px solid $grey_5;
    }

    table {
        width: 100%;
        border-collapse: collapse;

        tr {

            th,
            &:first-child {
                font-family: $primary_600_font;
                font-weight: 600;
                background-color: $grey_5;
                color: $white;

                td {
                    border-right: 1px solid;

                    &:last-of-type {
                        border-color: $grey_5;
                    }
                }
            }

            &:not(:first-child):nth-child(odd) {
                background-color: $grey_1;
            }

            th,
            td {
                padding: 10px;
                border: 1px solid $grey_5;
                text-align: left;
                vertical-align: top;
                min-width: 210px;

                h2,
                h3,
                h4 {
                    margin-top: 0;
                }
            }
        }
    }

    & + span.mobile-prompt {
        display: none;
    }
}

div.numbered-pagination {
    margin-top: 3em;
    padding-top: 2em;
    text-align: center;
    border-top: 1px solid $grey_2;
    text-align: center;

    &.font-roboto-700 {

        ul {

            li {
                font-family: $roboto_700_font;
                font-weight: 700;
            }
        }
    }

    &.black {

        ul {

            li {
                
                a {
                    color: $black;
                }

                &.prev-list-item,
                &.next-list-item {

                    a {

                        svg {

                            path {
                                stroke: $black;
                            }
                        }
                    }
                }
            }
        }
    }

    ul {
        margin: 0;

        li {
            display: inline-block;
            font-family: $primary_600_font;
            margin-right: 30px;

            &.prev-list-item,
            &.next-list-item {

                a {

                    .no-touchevents &:hover {
                        border-bottom: none;

                        svg {

                            path {
                                stroke: $orange;
                            }
                        }
                    }

                    svg {
                        width: 10px;
                        vertical-align: middle;
                        margin-top: -3px;

                        path {
                            transition: all 0.2s ease-in-out;
                            stroke: $grey_5;
                        }
                    }
                }
            }

            a {
                color: $grey_5;
                transition: color 0.2s ease-in-out;

                .no-touchevents &:hover {
                    color: $orange;
                    border-bottom: 2px solid;
                }
            }

            span {

                &.current {
                    color: $orange;
                    border-bottom: 2px solid;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

div.iframe-wrapper {
    border-radius: 20px;

    div.iframe-inner-wrapper {
    	overflow: hidden;
	    padding-top: 56.25%;
	    position: relative;
    
	    iframe {
	        border: 0;
	        height: 100%;
	        left: 0;
	        position: absolute;
	        top: 0;
	        width: 100%;
	    }
	}
}

//------ Share Articles ------//

div.share-articles {
    position: relative;

    nav {

        &.share-links {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 1em;
            padding: 1em 2em;
            border-radius: 40px;
            border: 1px solid $grey_5;
            opacity: 0;
            visibility: hidden;
            transition: all 0.5s ease-in-out;
            width: 100%;
            width: fit-content;
            min-width: 285px;

            .ie & {
                width: 285px;
            }

            &.open {
                opacity: 1;
                visibility: visible;
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;
                    margin-right: 1em;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    a {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        border: 1px solid $black;
                        padding: 8px;
                        height: 40px;
                        width: 40px;
                        transition: all 0.2s ease-in-out;

                        svg {
                            width: 20px;

                            path {
                                transition: all 0.2s ease-in-out;
                            }
                        }

                        .no-touchevents &:hover {
                            background-color: $grey_5;

                            svg {
                                
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//------ Cookie Popup ------//

div.cookie-popup {
    position: fixed;
    right: 1em;
    bottom: 1em;
    visibility: hidden;
    opacity: 0;
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 2em;
    width: calc(100% - 2em);
    max-width: 500px;
    box-shadow: 1px 0 6px 1px rgba(0, 0, 0, 0.10);
    z-index: 999;

    &.active {
        visibility: visible;
        opacity: 1;
    }

    p {
        margin-top: 0;
        font-size: 0.9em;
        line-height: 1.8em;

        a {
            border-bottom: 1px solid;
            white-space: nowrap;
        }
    }

    a.btn {
        margin-top: 1.5em;
    }
}

article {

    &.default-article {

        & > a {
            display: block;

            .no-touchevents &:hover {

                div.image-wrapper {

                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }

        div.image-wrapper {
            position: relative;
            display: block;
            width: 100%;
            height: 0;
            overflow: hidden;
            margin-bottom: 1.5em;

            &.portrait {
                padding-top: 133.333333333%;
            }

            &.square {
                padding-top: 100%;
            }

            &.landscape {
                padding-top: 75%;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                aspect-ratio: 4/3;
                object-fit: cover;
                transition: all 0.2s ease-in-out;
            }

            span {

                &.distance,
                &.highlight {
                    position: absolute;
                    padding: 0.5em;
                    background-color: $white;
                    @include uppercase-text;
                    font-family: $primary_600_font;
                    font-size: 0.9em;
                    line-height: 1em;
                    z-index: 1;

                    span.text {
                        position: relative;
                        top: 2px;
                        left: 2px;
                    }
                }

                &.distance {
                    top: 1em;
                    right: 1em;
                }

                &.highlight {
                    bottom: 1em;
                    left: 1em;
                }
            }
        }

        div.text-wrapper {

            h2, h3 {

                & + p {
                    
                    &.uppercase {
                        margin-top: 0.5em;
                    }
                }
            }

            time {
                margin-top: 1em;
                margin-bottom: 0;
            }
        }
    }

    &.next-article {
        padding: 4em;
        margin-top: 10em;
        display: flex;
        flex-direction: row;

        &.grey-3-bg,
        &.grey-4-bg,
        &.grey-5-bg {

            div.content-wrapper {

                div.content-wrapper__text {
                    color: $white;

                    span, p {
                        color: $white;
                    }
                }

                div.content-wrapper__link {
                    color: $white;

                    a {

                        .no-touchevents &:hover {
                            background-color: $white;

                            svg {

                                path {
                                    stroke: $grey_5;
                                }
                            }
                        }

                        svg {

                            path {
                                stroke: $white;
                            }
                        }
                    }
                }
            }
        }

        div.image-wrapper {
            margin-top: -14em;
            width: 40%;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        div.content-wrapper {
            padding-left: 4em;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            width: 60%;

            div.content-wrapper__text {
                padding-right: 2em;
                max-width: 500px;

                span {
                    margin-bottom: 1em;
                }

                p {
                    margin-top: 0.25em;
                }
            }

            div.content-wrapper__link {
                color: $orange;

                a {
                    display: flex;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border: 1px solid;
                    border-radius: 50%;
                    transition: all 0.2s ease-in-out;

                    .no-touchevents &:hover {
                        background-color: $orange;

                        svg {

                            path {
                                stroke: $white;
                            }
                        }
                    }

                    svg {
                        width: 10px;
                        margin-right: -3px;

                        path {
                            stroke: $orange;
                            transition: all 0.2s ease-in-out;
                        }
                    }
                }
            }
        }
    }
}

//------ Legacy Browser Notice ------//
    
div.legacy-browser-notice {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    max-width: 400px;
    width: 100%;
    font-size: 1em;
    color: $white;
    padding: 22px;
    background-color: #00aadd;

    a {
        color: $white;
        border-bottom: 1px solid;
    }

    ul.important-notice {
        padding: 0;
        list-style-type: 0;

        li {
            display: inline;
            margin-right: 10px;

            &.uppercase {
                text-transform: uppercase;
                font-size: 1.2em;
                vertical-align: middle;
            }

            img {
                vertical-align: middle;
            }
        }
    }

    p {

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

//------ Animation Styles ------//

@keyframes rotation {
    0% { 
        transform: rotate(0deg);
    }
    100% { 
        transform: rotate(360deg);
    }
}

@keyframes rotation-scale {
    from {
        transform: rotate(0deg) scale(0.7, 0.7);
    }
    50% {
        transform: rotate(180deg) scale(1, 1);
    }
    to {
        transform: rotate(360deg) scale(0.7, 0.7);
    }
}

@keyframes loading {
    from {
        left: -100px;
        width: 30%;
    }
    50% {
        width: 30%;
    }
    70% {
        width: 50%;
    }
    80% {
        width: 60%;
        left: 70%;
    }
    to {
        left: 100%;
    } 
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

//------ 56.25em = 900px ------//

@media screen and (max-width: 56.25em) {

    div.table-wrapper {

        & + span.mobile-prompt {
            display: block;
        }
    }

}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    article {

        &.next-article {
            margin-top: 8em;
            flex-direction: column;

            div.image-wrapper {
                margin-top: -12em;
                width: 100%;
            }

            div.content-wrapper {
                padding-left: 0;
                padding-top: 2em;
                width: 100%;
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {
    
    h1 {
        font-size: 1.8em;
        line-height: 1.2em;
    }

    h2, h3 {
        font-size: 1.4em;
        line-height: 1.2em;
    }

    h3,
    h4 {
        line-height: 1.2em;
    }

    div.wp-caption {
        margin: 1em 0;
    }

    hr {
        margin: 2em 0;
    }

    img {

        &.aligncenter {
            margin: 1em auto !important;
        }

        &.alignleft {
            display: block;
            float: unset;
            margin: 1em 0 !important;
        }

        &.alignright {
            display: block;
            float: unset;
            margin: 1em 0 !important;
        }
    }

    div.cta-block {

        span {
            display: block;

            &.heading {
                font-size: 1.6em;
            }

            &.pricing {
                font-family: $roboto_regular_font;
                font-weight: 400; 
            }
        }
    }

    article {

        &.default-article {
            padding-bottom: 2em;
            border-bottom: 1px solid $grey_2;

            &:last-of-type {
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        &.next-article {
            padding: 2em;

            div.image-wrapper {
                margin-top: -10em;
            }

            div.content-wrapper {
                flex-direction: column;
                align-items: flex-start;

                div.content-wrapper__text {
                    padding-right: 0;
                    max-width: unset;
                }

                div.content-wrapper__link {
                    margin-top: 1em;
                }
            }
        }
    }

    div.no-results {
        margin: auto auto 2em auto;
    
        div.no-results__content {

            p {

                a {

                    &.btn {
                        margin-top: 0;
                    }
                }
            }
        }
    }

}

//------ 25em = 400px ------//

@media screen and (max-width: 25em) {

    p {

        & > a.btn {

            &:nth-of-type(2n) {
                margin-left: 0;
                margin-top: 1em;
            }
        } 
    }

}

//------ Media Queries (Exact Match) ------//

@include breakpoint(ipad_portrait) {

    article {

        &.next-article {
            margin-top: 8em;
            flex-direction: column;

            div.image-wrapper {
                margin-top: -12em;
                width: 100%;
            }

            div.content-wrapper {
                padding-left: 0;
                padding-top: 2em;
                width: 100%;
            }
        }
    }

}