//------ Courses - Section Styles ------//

section {

    &.courses {
        padding-top: 4em;

    	div.courses__wrapper {

            aside.courses__sidebar {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 2em;

                form {

                    &.courses-filter {
                        width: 100%;

                        div.row {
                            display: flex;
                            flex-direction: row;
                        }

                        div.date-toggle {
                            display: flex;
                            flex-direction: row;
                            margin-right: 20px;

                            div.date-toggle__buttons {
                                display: flex;
                                flex-direction: row;
                                margin-right: 10px;

                                a {

                                    &.previous-btn {
                                        margin-right: 5px;
                                    }

                                    &.next-btn {
                                        margin-left: 5px;
                                    }

                                    &.previous-btn,
                                    &.next-btn {
                                        padding: 10px;

                                        span {

                                            &.icon {
                                                display: block;
                                                height: 15px;

                                                svg {
                                                    height: 100%;

                                                    path {
                                                        transition: all 0.2s ease-in-out;
                                                    }
                                                }
                                            }
                                        }

                                        &.disabled {
                                            pointer-events: none;

                                            span {

                                                &.icon {

                                                    svg {

                                                        path {
                                                            fill: #9FA1A5;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        div.notice {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid $blue_1;
                            border-left: 15px solid $blue_1;
                            padding: 0.5em 1em;
                            border-radius: 2px;
                            width: 100%;
                            max-width: 550px;

                            div.notice__icon {
                                width: 22px;
                                height: 22px;
                                min-width: 22px;
                                margin-right: 10px;
                            }

                            div.notice__text {
                                margin-top: 1px;

                                p {
                                    font-family: $roboto_700_font;
                                    font-weight: 700;
                                    line-height: 1.4em;
                                    color: $black;

                                    &:first-of-type {
                                        margin: 0;
                                    }
                                }
                            }
                        }

                        div.btn-wrapper {

                            button {

                                &.btn {
                                    margin-left: 1em;
                                }
                            }
                        }
                    }
                }
            }

    		div.courses__content {

                &.no-results {
                    text-align: center;

                    p {
                        margin: 0;
                    }
                }

                div.day {
                    background-color: $light_grey;
                    padding: 2em;
                    border-radius: 5px;
                    margin-bottom: 2em;

                    span {

                        &.day__heading {
                            display: block;
                            font-size: 1.6em;
                            font-family: $roboto_regular_font;
                            font-weight: 400;
                            line-height: 1em;
                            margin-bottom: 1.25em;
                            color: $black;
                        }
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                article {

                    &.course {
                        margin-bottom: 2em;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        &.orange {

                            .no-touchevents &:hover,
                            &:focus {

                                a {
                                    border-color: $orange;
                                }
                            }
                        }

                        &.blue {

                            .no-touchevents &:hover,
                            &:focus {

                                a {
                                    border-color: $blue;
                                }
                            }
                        }

                        &.green {

                            .no-touchevents &:hover,
                            &:focus {

                                a {
                                    border-color: $green_1;
                                }
                            }
                        }

                        &.purple {

                            .no-touchevents &:hover,
                            &:focus {

                                a {
                                    border-color: $purple_1;
                                }
                            }
                        }

                        &.mauve {

                            .no-touchevents &:hover,
                            &:focus {

                                a {
                                    border-color: $mauve;
                                }
                            }
                        }

                        a {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 2em;
                            border-radius: 5px;
                            background-color: $white;
                            border: 2px solid transparent;
                            box-shadow: 1px 0 6px 1px rgba(0, 0, 0, 0.10);
                            transition: all 0.2s ease-in-out;

                            div.course__info {
                                width: 50%;
                                font-family: $roboto_regular_font;
                                font-weight: 400;

                                div.title {
                                    display: flex;
                                    flex-direction: row;
                                    margin-bottom: 0.5em;

                                    h3 {
                                        font-size: 1.4em;
                                        font-family: $roboto_regular_font;
                                        font-weight: 400;
                                        color: $black;
                                    }

                                    span {

                                        &.icon {
                                            width: 15px;
                                            min-width: 15px;
                                            margin-top: 4px;
                                            margin-right: 10px;

                                            svg {

                                                path {
                                                    fill: $black;
                                                }
                                            }
                                        }
                                    }
                                }

                                p {
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;  
                                    overflow: hidden;
                                    line-height: 1.6em;
                                    margin: 0;
                                }

                                span {
                                    display: block;

                                    &.birth-preference {
                                        display: none;
                                        align-items: center;
                                        margin-top: 1em;
                                        text-transform: uppercase;
                                        letter-spacing: 0.1em;

                                        //------ 50em = 800px ------//

                                        @media screen and (min-width: 50em) {

                                            &.desktop {
                                                display: flex;
                                            }

                                        }

                                        span {

                                            &.icon {
                                                margin-top: -2px;
                                                margin-right: 5px;

                                                &.square {
                                                    width: 10px;
                                                    height: 10px;
                                                    
                                                    &.orange {
                                                        background-color: $orange;
                                                    }

                                                    &.blue {
                                                        background-color: $blue;
                                                    }

                                                    &.green {
                                                        background-color: $green_1;
                                                    }

                                                    &.purple {
                                                        background-color: $purple_1;
                                                    }

                                                    &.mauve {
                                                        background-color: $mauve;
                                                    }
                                                }

                                                &.circle {
                                                    width: 10px;
                                                    height: 10px;
                                                    border-radius: 50%;

                                                    &.orange {
                                                        background-color: $orange;
                                                    }

                                                    &.blue {
                                                        background-color: $blue;
                                                    }

                                                    &.green {
                                                        background-color: $green_1;
                                                    }

                                                    &.purple {
                                                        background-color: $purple_1;
                                                    }

                                                    &.mauve {
                                                        background-color: $mauve;
                                                    }
                                                }

                                                &.triangle {
                                                    width: 0;
                                                    height: 0;
                                                    border-left: 5px solid transparent;
                                                    border-right: 5px solid transparent;

                                                    &.orange {
                                                        border-bottom: 10px solid $orange;
                                                    }

                                                    &.blue {
                                                        border-bottom: 10px solid $blue;
                                                    }

                                                    &.green {
                                                        border-bottom: 10px solid $green_1;
                                                    }

                                                    &.purple {
                                                        border-bottom: 10px solid $purple_1;
                                                    }

                                                    &.mauve {
                                                        border-bottom: 10px solid $mauve;
                                                    }
                                                }
                                            }

                                            &.text {
                                                font-size: 0.85em;
                                                line-height: 1em;
                                            }
                                        }
                                    }
                                }
                            }

                            div.course__data {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-evenly;
                                width: 50%;
                                margin-top: 7px;

                                div.teacher {
                                    display: flex;
                                    flex-direction: column;
                                    font-family: $roboto_regular_font;
                                    font-weight: 400;
                                    padding-left: 2em;
                                    min-width: 33.3333333%;

                                    span {
                                        display: block;

                                        &.image {
                                            width: 80px;
                                            height: 80px;
                                            border-radius: 50%;
                                            margin: 0 auto;
                                            overflow: hidden;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }
                                        }

                                        &.name {
                                            text-align: center;
                                            font-size: 1.2em;
                                            line-height: 1.4em;
                                            margin-top: 0.5em;
                                            color: $black;
                                            white-space: nowrap;
                                        }

                                        &.instructor {
                                            display: none;
                                        }
                                    }
                                }

                                div.date {
                                    display: flex;
                                    flex-direction: column;
                                    font-family: $roboto_regular_font;
                                    font-weight: 400;
                                    padding-left: 2em;
                                    min-width: 33.3333333%;

                                    span {
                                        display: block;

                                        &.icon {
                                            width: 40px;

                                            svg {

                                                path {
                                                    fill: $black;
                                                }
                                            }
                                        }

                                        &.dates {
                                            font-size: 1.2em;
                                            line-height: 1.4em;
                                            margin-top: 1.25em;
                                            color: $black;
                                            white-space: nowrap;
                                        }

                                        &.sessions {
                                            line-height: 1em;
                                            margin-top: 0.5em;
                                        }
                                    }
                                }

                                div.time {
                                    display: flex;
                                    flex-direction: column;
                                    font-family: $roboto_regular_font;
                                    font-weight: 400;
                                    padding-left: 2em;
                                    min-width: 33.3333333%;

                                    span {
                                        display: block;

                                        &.icon {
                                            width: 40px;
                                            margin-top: -3px;

                                            svg {

                                                path {
                                                    fill: $black;
                                                }
                                            }
                                        }

                                        &.times {
                                            font-size: 1.2em;
                                            line-height: 1.4em;
                                            margin-top: 1.25em;
                                            color: $black;
                                            white-space: nowrap;

                                            span {
                                                display: inline-block;

                                                &.min-00 {
                                                    display: none;
                                                }
                                            }
                                        }

                                        &.days {
                                            line-height: 1em;
                                            margin-top: 0.5em;
                                        }
                                    }
                                }

                                span {
                                    display: block;

                                    &.birth-preference {
                                        display: none;
                                        align-items: center;
                                        margin-top: 1.5em;
                                        text-transform: uppercase;
                                        letter-spacing: 0.1em;

                                        //------ 50em = 800px ------//

                                        @media screen and (max-width: 50em) {

                                            &.mobile {
                                                display: flex;
                                            }

                                        }

                                        span {

                                            &.icon {
                                                margin-top: -3px;
                                                margin-right: 5px;

                                                &.square {
                                                    width: 10px;
                                                    height: 10px;
                                                    
                                                    &.orange {
                                                        background-color: $orange;
                                                    }

                                                    &.blue {
                                                        background-color: $blue;
                                                    }

                                                    &.green {
                                                        background-color: $green_1;
                                                    }

                                                    &.purple {
                                                        background-color: $purple_1;
                                                    }

                                                    &.mauve {
                                                        background-color: $mauve;
                                                    }
                                                }

                                                &.circle {
                                                    width: 10px;
                                                    height: 10px;
                                                    border-radius: 50%;

                                                    &.orange {
                                                        background-color: $orange;
                                                    }

                                                    &.blue {
                                                        background-color: $blue;
                                                    }

                                                    &.green {
                                                        background-color: $green_1;
                                                    }

                                                    &.purple {
                                                        background-color: $purple_1;
                                                    }

                                                    &.mauve {
                                                        background-color: $mauve;
                                                    }
                                                }

                                                &.triangle {
                                                    width: 0;
                                                    height: 0;
                                                    border-left: 5px solid transparent;
                                                    border-right: 5px solid transparent;

                                                    &.orange {
                                                        border-bottom: 10px solid $orange;
                                                    }

                                                    &.blue {
                                                        border-bottom: 10px solid $blue;
                                                    }

                                                    &.green {
                                                        border-bottom: 10px solid $green_1;
                                                    }

                                                    &.purple {
                                                        border-bottom: 10px solid $purple_1;
                                                    }

                                                    &.mauve {
                                                        border-bottom: 10px solid $mauve;
                                                    }
                                                }
                                            }

                                            &.text {
                                                font-size: 0.85em;
                                                line-height: 1em;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    		}
    	}
	}
}

//------ 78.125em = 1250px ------//

@media screen and (max-width: 78.125em) {

    section {

        &.courses {
            overflow: visible;

            div.courses__wrapper {

                div.courses__content {

                    article {

                        &.course {

                            a {

                                div.course__info {
                                    width: 40%;
                                }

                                div.course__data {
                                    width: 60%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

//------ 64em = 1024px ------//

@media screen and (max-width: 64em) {

    section {

        &.courses {
            overflow: hidden;

            div.courses__wrapper {
                flex-direction: column;

                aside.courses__sidebar {
                    width: 100%;

                    form {

                        &.courses-filter {

                            div.btn-wrapper {
                                margin-top: 1em;
                            }
                        }
                    }
                }

                div.courses__content {

                    article {

                        &.course {

                            a {
                                flex-direction: column;

                                div.course__info {
                                    width: 100%;
                                }

                                div.course__data {
                                    width: 100%;
                                    margin-top: 2em;
                                    justify-content: flex-start;

                                    div.teacher {
                                        padding-left: 0;
                                        min-width: unset;
                                    }

                                    div.date,
                                    div.time {
                                        padding-left: 4em;
                                        min-width: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.courses {

            div.courses__wrapper {

                div.courses__content {

                    article {

                        &.course {

                            a {

                                div.course__data {
                                    flex-direction: column;
                                    margin-top: 1em;

                                    div.teacher {
                                        flex-direction: row;
                                        align-items: flex-start;
                                        flex-wrap: wrap;
                                        margin-bottom: 1em;

                                        span {

                                            &.image {
                                                width: 35px;
                                                height: 35px;
                                                min-width: 35px;
                                                margin: 0 10px 0 0;
                                            }

                                            &.name {
                                                font-size: 1em;
                                                line-height: 1em;
                                                margin-top: 4px;
                                                width: calc(100% - 45px);
                                                text-align: left;
                                            }

                                            &.instructor {
                                                display: block;
                                                width: calc(100% - 45px);
                                                margin-top: -12px;
                                                margin-left: 45px;
                                                font-size: 0.65em;
                                                line-height: 1em;
                                            }
                                        }
                                    }

                                    div.date {
                                        flex-direction: row;
                                        align-items: flex-start;
                                        flex-wrap: wrap;
                                        padding-left: 0;
                                        margin-bottom: 1em;

                                        span {

                                            &.icon {
                                                width: 35px;
                                                min-width: 35px;
                                                margin-right: 10px;
                                            }

                                            &.dates {
                                                font-size: 1em;
                                                line-height: 1em;
                                                margin-top: 4px;
                                                margin-bottom: 8px;
                                                width: calc(100% - 45px);
                                            }

                                            &.sessions {
                                                font-size: 0.65em;
                                                margin-top: -10px;
                                                width: calc(100% - 45px);
                                                margin-left: 45px;
                                            }
                                        }
                                    }

                                    div.time {
                                        flex-direction: row;
                                        align-items: flex-start;
                                        flex-wrap: wrap;
                                        padding-left: 0;

                                        span {

                                            &.icon {
                                                width: 35px;
                                                min-width: 35px;
                                                margin-right: 10px;
                                            }

                                            &.times {
                                                font-size: 1em;
                                                line-height: 1em;
                                                margin-top: 0;
                                                margin-bottom: 0;
                                                width: calc(100% - 45px);
                                            }

                                            &.days {
                                                margin-top: -13px;
                                                font-size: 0.65em;
                                                width: calc(100% - 45px);
                                                margin-left: 45px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.courses {
            padding: 2em 0;

            div.courses__wrapper {

                aside.courses__sidebar {

                    form {

                        &.courses-filter {

                            div.select-wrapper {
                                min-width: unset;
                                margin-bottom: 1em;
                            }

                            div.row {
                                flex-direction: column;
                            }

                            div.date-toggle {
                                margin-right: 0;
                                margin-bottom: 0.5em;

                                div.select-wrapper {
                                    width: 100%;
                                }
                            }

                            div.notice {
                                padding: 0.5em;
                                margin-top: 2em;

                                div.notice__icon {
                                    width: 18px;
                                    height: 18px;
                                    min-width: 18px;
                                }

                                div.notice__text {
                                    margin-top: 2px;

                                    p {
                                        font-size: 0.65em;
                                    }
                                }
                            }
                        }
                    }
                }

                div.courses__content {

                    div.day {
                        padding: 1em;

                        span {

                            &.day__heading {
                                font-size: 1.4em;
                                margin-bottom: 1em;
                            }
                        }
                    }

                    article {

                        &.course {

                            a {
                                padding: 1em;

                                div.course__info {

                                    div.title {

                                        span {

                                            &.icon {
                                                margin-top: 0;
                                            }
                                        }

                                        h3 {
                                            font-size: 1.2em;
                                            line-height: 1.2em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}