//------ Courses Banner - Section Styles ------//

section {

    &.courses-banner {

        &.has-illustration {
            min-height: 200px;
            padding-bottom: 0;

            &.field-1 {
                margin-top: 154px;
                padding-top: 4em;

                .loaded & {
                    margin-top: var(--hh); 
                }
            }
        }

        div.container {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            div.text-wrapper {
                width: 50%;

                div.text-wrapper__inner {
                    max-width: 550px;
                    padding-bottom: 4em;

                    p {

                        a:first-of-type {
                                    
                            &.btn {
                                margin-top: 1em;
                            }
                        }

                        a {

                            &:not(.btn) {
                                transition: all 0.2s ease-in-out;
                                border-bottom: 1px solid;
                                font-family: $primary_600_font;
                                font-weight: 600;

                                .no-touchevents &:hover {
                                    color: $grey_3;
                                    border-color: $grey_3;
                                }
                            }
                        }
                    }

                    ul, ol {

                        &:not(.menu) {
                            padding: 1em 0;
                            margin: 0 0 0 1.1em;
                        }
                    }

                    ol {

                        li {
                            margin-bottom: 1em;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            div.media-wrapper {
                width: 50%;
                padding-left: 2em;

                svg {
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}

//------ 64em = 1024px ------//

@media screen and (max-width: 64em) {

    section {

        &.courses-banner {

            div.container {
                flex-direction: column;

                div.text-wrapper {
                    width: 100%;

                    div.text-wrapper__inner {
                        max-width: unset;
                        padding-bottom: 2em;
                    }
                }

                div.media-wrapper {
                    width: 100%;
                    padding-left: 0;

                    svg {
                        max-width: 500px;
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.courses-banner {
            position: relative;

            &.has-illustration {

                &.field-1 {
                    margin-top: 144px;
                    padding-top: 2em;

                    .loaded & {
                        margin-top: var(--hh); 
                    }
                }
            }

            div.container {

                div.text-wrapper {

                    div.text-wrapper__inner {
                        padding-bottom: 2em;

                        p {
                            
                            a:first-of-type {
                                    
                                &.btn {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}