//------ Course Instructor - Section Styles ------//

section {

    &.course-instructor {

        &.font-roboto {
            font-family: $roboto_regular_font;
            font-weight: 400;

            h1, h2, h3 {
                font-family: $roboto_regular_font;
                font-weight: 400;
            }
        }

        div.intro-wrapper {
            
            p {
                line-height: 1.6em;
            }
        }

        div.container {
            
            div.course-instructor__wrapper {

                div.instructor {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 2em;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &.image--right {

                        div.instructor__image {
                            order: 2;
                        }

                        div.instructor__text {
                            order: 1;
                            padding-right: 4em;
                        }
                    }

                    div.instructor__image {
                        width: 20%;
                        min-width: 200px;
                        max-width: 200px;
                        position: relative;

                        img {
                            width: 200px;
                            height: 200px;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    div.instructor__text {
                        width: 80%;
                        padding-left: 4em;

                        h1, h2, h3 {
                            color: $black;
                        }

                        p {
                            line-height: 1.6em;
                        }
                    }
                }
            }
        }
	}
}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.course-instructor {

            div.container {
                
                div.course-instructor__wrapper {

                    div.instructor {
                        flex-direction: column;
                        align-items: flex-start;

                         &.image--right {

                            div.instructor__image {
                                order: 1;
                            }

                            div.instructor__text {
                                order: 2;
                                padding-right: 0;
                            }
                        }

                        div.instructor__image {
                            width: 100%;
                        }

                        div.instructor__text {
                            width: 100%;
                            padding-left: 0;
                            margin-top: 1em;
                        }
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.course-instructor { 

            div.intro-wrapper {
                text-align: left;
            }
        }
    }

}