//------ Slick Slider Styles ------//

button.slick-prev,
button.slick-next {
    font-size: 0;
    position: absolute;
    bottom: 20px;
    color: black;
    opacity: 1;
    border: 0;
    background: none;
    z-index: 1;
}

ul.slick-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 1.5em 0 0 0;
    z-index: 997;
    
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10px;
        height: 10px;
        margin: 0 15px 0 0;

        &:only-child {
            display: none;
        } 

        button {
            display: block;
            width: 10px;
            height: 10px;
            padding: 0;
            background-color: transparent;
            border: 1px solid $grey_5;
            border-radius: 50%;
            text-indent: -9999px;
            transition: all 0.3s ease-in-out;

            &:before {
                display: none;
            }
        }

        &:last-child {
            margin: 0;
        }

        .no-touchevents &:hover,
        &.slick-active {
            
            button {
                background-color: $grey_5;
            }
        }
    }
}