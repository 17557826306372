//------ Podcast CTA - Section Styles ------//

section {

    &.podcast-cta {

        &.grey-3-bg,
        &.grey-4-bg,
        &.grey-5-bg {

            div.cta-wrapper {

                div.cta-wrapper__widget {

                    p {
                        color: $white;
                    }
                }

                div.cta-wrapper__links {

                    p {
                        color: $white;
                    }
                }
            }
        }

        div.cta-wrapper {

            div.cta-wrapper__widget {

                .ie & {
                    float: left;
                    width: 50%;
                }

                p {

                    &.uppercase {
                        margin-top: 0;
                        margin-bottom: 1.5em;
                    }
                }
            }

            div.cta-wrapper__links {
                padding-left: 4em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                .ie & {
                    float: left;
                    width: 50%;
                }

                p {

                    &.uppercase {
                        margin-top: 0;
                        margin-bottom: calc(1.5em - 5px);
                    }
                }

                div.links {
                    text-align: center;

                    div.link {
                        display: inline-block;
                        margin: 5px;

                        a {
                            display: block;
                        }

                        img {
                            max-width: 200px;
                            max-height: 85px;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.podcast-cta {

            div.cta-wrapper {

                &.grid-container {

                    &.grid-one-half {
                        grid-template-columns: 100%;
                        grid-row-gap: 2em;
                    }
                }

                div.cta-wrapper__links {
                    padding-left: 0;
                }
            }
        }
    }

}