//------ Course Banner - Section Styles ------//

section {

    &.course-banner {

        div.course-images {

            &.blocks {
                position: relative;
            	display: none;
            	flex-direction: row;

                //------ 50em = 800px ------//

                @media screen and (min-width: 50em) {
                    display: flex;
                }

                a.gallery-btn {
                    position: absolute;
                    right: 2em;
                    bottom: 2em;
                }

            	div.column {
            		width: 50%;

            		div.media-wrapper {
                        position: relative;
                        overflow: hidden;
                        height: 450px;

    	        		img {
    					    width: 100%;
    					    height: 100%;
    					    aspect-ratio: 4/3;
    					    object-fit: cover;
    	        		}
            		}

            		div.row {

            			&.row-1 {
                            display: flex;
                            flex-direction: row;
            				overflow: hidden;
                            margin-left: 10px;

        					div.media-wrapper {
            					width: 50%;
                                height: 150px;

            					&:first-of-type {
            						margin-right: 10px;
            					}
        					}
            			}

            			&.row-2 {
            				margin-top: 10px;
            				margin-left: 10px;
            				overflow: hidden;

                            div.media-wrapper {
                                height: 290px;
                            }
            			}
            		}
            	}
            }

            &.slider {
                display: none;
                position: relative;

                //------ 50em = 800px ------//

                @media screen and (max-width: 50em) {
                    display: block;
                }

                div.course-images__slider {

                    div.slide {

                        //------ 37.5em = 600px ------//

                        // @media screen and (max-width: 37.5em) {
                        //     max-height: 300px;
                        // }

                        img {
                            width: 100%;
                        }
                    }
                }

                span.course-images__counter {
                    position: absolute;
                    right: 1em;
                    bottom: 1em;
                    z-index: 1;
                    background-color: rgba(0, 0, 0, 0.4);
                    color: $white;
                    font-family: $roboto_regular_font;
                    font-weight: 400;
                    line-height: 1em;
                    padding: 0.5em;
                    border-radius: 5px;
                }
            }
        }

        div.course-information {

            div.course-information__text {
                display: flex;
                flex-direction: row;
                margin: 4em 0 2em 0;
                font-family: $roboto_regular_font;
                font-weight: 400;

                h1, h2, h3 {
                    font-family: $roboto_regular_font;
                    font-weight: 400;
                    color: $black;
                }

                div.column {

                    &.text {
                        width: 70%;
                        padding-right: 4em;

                        div.description {
                            width: 100%;
                            max-width: 600px;

                            p {
                                line-height: 1.6em;
                            }
                        }
                    }

                    &.cta {
                        width: 30%;
                    }
                }
            }

            div.course-information__details {
                display: flex;
                flex-direction: row;

                div.column {

                    &.details {
                        width: 70%;
                        padding-right: 4em;
                        font-family: $roboto_regular_font;
                        font-weight: 400;

                        h1, h2, h3 {
                            font-family: $roboto_regular_font;
                            font-weight: 400;
                            color: $black;
                        }

                        div.details-list {
                            margin-top: 2em;

                            div.detail {
                                display: flex;
                                flex-direction: row;
                                width: 100%;

                                span {
                                    display: block;

                                    &.icon {
                                        width: 30px;

                                        svg {
                                            max-height: 25px;

                                            path {
                                                fill: $blue-1;
                                            }
                                        }
                                    }

                                    &.data {
                                        width: 100%;
                                        padding-left: 1em;

                                        span {
                                            display: block;

                                            &.heading {
                                                color: $black;
                                                font-size: 1.2em;
                                                margin-bottom: 0.5em;
                                            }

                                            &.toggle-map {
                                                display: inline-flex;
                                                align-items: center;
                                                font-family: $roboto_700_font;
                                                font-weight: 700;
                                                color: $blue-1;
                                                margin: 0.5em 0;
                                                cursor: pointer;
                                                transition: all 0.2s ease-in-out;

                                                span {

                                                    &.toggle-map__icon {
                                                        transition: all 0.2s ease-in-out;
                                                        display: flex;

                                                        svg {

                                                            path {
                                                                fill: $blue-1;
                                                                transition: all 0.2s ease-in-out;
                                                            }
                                                        }
                                                    }
                                                }

                                                svg {
                                                    width: 15px;
                                                    margin-left: 10px;
                                                }

                                                &.open {
                                                    color: $black;

                                                    span {

                                                        &.toggle-map__icon {
                                                            transform: scaleY(-1);

                                                            svg {

                                                                path {
                                                                    fill: $black;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        
                                        p {
                                            margin: 0;
                                            line-height: 1.6em;
                                        }

                                        div.map-wrapper {

                                            &.hidden {
                                                display: none;
                                            }

                                            div.acf-map {
                                                width: 100%;
                                                height: 250px;

                                                img {
                                                   max-width: inherit !important;
                                                }
                                            }

                                            a {
                                                display: inline-block;
                                                margin-top: 1em;
                                                font-family: $roboto_700_font;
                                                font-weight: 700;
                                                color: $blue-1;
                                                transition: all 0.2s ease-in-out;

                                                .no-touchevents &:hover {
                                                    color: $black;
                                                }
                                            }
                                        }
                                    }
                                }

                                ul.sessions {
                                    list-style-type: none;
                                    padding: 0;
                                    margin: 0;

                                    li {
                                        line-height: 1.4em;
                                        margin-bottom: 0.5em;

                                        span {

                                            &.sessions__title {
                                                display: block;
                                            }

                                            &.sessions__date,
                                            &.sessions__time {
                                                display: inline-block;
                                            }

                                            &.sessions__time {

                                                span {
                                                    display: inline-block;

                                                    &.min-00 { display: none; }
                                                }
                                            }

                                            &.sessions__date {
                                                margin-right: 5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.accreditations {
                        width: 30%;

                        div.accreditations-list {

                            div.accreditation {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.course-banner {

            div.container {
                width: 100%;
            }

            div.course-information {
                width: 90%;
                max-width: 1400px;
                margin: 0 auto;

                div.course-information__text {
                    flex-direction: column;
                    margin: 2em 0;

                    div.column {

                        &.text {
                            width: 100%;
                            padding-right: 0;
                        }

                        &.cta {
                            width: 100%;

                            div.cta-block {
                                padding: 0;
                                border-radius: 0;
                                border: none;
                                text-align: left;

                                span.pricing {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                div.course-information__details {
                    flex-direction: column;

                    div.column {

                        &.details {
                            width: 100%;
                            padding-right: 0;

                            div.details-list {

                                div.detail {

                                    span.icon {
                                        text-align: center;
                                    }
                                }
                            }
                        }

                        &.accreditations {
                            width: 100%;
                            margin-top: 1em;
                        }
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.course-banner {

            div.course-information {

                div.course-information__details {

                    div.column {

                        &.details {

                            div.details-list {

                                &.grid-container {

                                    &.grid-one-half {
                                        grid-template-columns: 100%;
                                        grid-row-gap: 1em;
                                    }
                                }

                                div.detail {

                                    span {

                                        &.data {

                                            span {

                                                &.heading {
                                                    font-size: 1em;
                                                    margin-bottom: 0;
                                                }

                                                &.toggle-map {
                                                    font-size: 0.9em;
                                                    margin: 0 0 0.5em 0;
                                                }
                                            }
                                            
                                            p {
                                                font-size: 0.9em;
                                            }

                                            div.map-wrapper {
                                                text-align: center;

                                                a {
                                                    font-size: 0.9em;
                                                }
                                            }
                                        }
                                    }

                                    ul.sessions {
                                        li {
                                            font-size: 0.9em;
                                        }
                                    }
                                }
                            }
                        }

                        &.accreditations {
                            display: none;
                        }
                    }
                }
            }
        }
    }

}