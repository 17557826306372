//------ Section Styles ------//

section {
    padding: 6em 0;
    overflow: hidden;

    &.no-padding {
        padding: 0;
    }

    &.padding-top {
        padding-bottom: 0;
    }

    &.padding-bottom {
        padding-top: 0;
    }

    &.font-roboto {

        div.container {

            div.intro-wrapper {
                font-family: $roboto_regular_font;
                font-weight: 400;

                h1, h2, h3 {
                    font-family: $roboto_regular_font;
                    font-weight: 400;
                }
            }
        }
    }

    &.grey-3-bg,
    &.grey-4-bg,
    &.grey-5-bg {

        div.container {

            &:not(.grey-1-bg):not(.grey-2-bg) {

                div.intro-wrapper {
                    color: $white;

                    p {
                        color: $white;

                        strong {
                            color: $white;
                        }
                    }

                    a:not(.btn) {
                        color: $white;

                        .no-touchevents &:hover {
                            color: $white;
                            border-color: $white;
                        }
                    }
                }
            }
        }
    }

    div.intro-wrapper {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        &.black-titles { 
            
            h1, h2, h3 {
                color: $black;
            }
        }

        &.start {
            margin-left: 0;
        }

        &.margin-bottom {
            margin-bottom: 2em;
        }

        div.iframe-wrapper {
            background-color: transparent;
            padding: 0;
            border-radius: 0;
        }

        svg, img {
            margin-top: 1em;

            &#trustpilot-reviews {
                width: 100%;
                max-width: 200px;
            }
        }

        a {

            &:not(.btn):not(.link) {
                transition: all 0.2s ease-in-out;
                border-bottom: 1px solid;
                font-family: $primary_600_font;
                font-weight: 600;

                .no-touchevents &:hover {
                    color: $grey_3;
                    border-color: $grey_3;
                }
            }

            &.btn {
                margin-top: 1em;
            }
        }
    }

    div.btn-wrapper {
        margin-top: 3em;

        a.btn + div.trustpilot-widget {
            margin-top: 1em;
        }
    }

    div.container {

        & > div.btn-wrapper {

            a.btn + div.trustpilot-widget {
                margin-top: 1em;
            }
        }
    }
}

//------ Banners ------//

@import "banners/landing-banner";
@import "banners/default-banner";
@import "banners/course-banner";
@import "banners/courses-banner";
@import "banners/article-banner";
@import "banners/pregnahub-banner";

//------ Page Builder ------//

@import "accordion-blocks";
@import "basic-content";
@import "courses";
@import "course-instructor";
@import "course-overview";
@import "course-cta";
@import "courses-cta";
@import "comparison-table";
@import "icon-cta";
@import "image-cta";
@import "faqs";
@import "featured-blocks";
@import "gallery";
@import "instagram-cta";
@import "icon-text-blocks";
@import "image-text-blocks";
@import "latest-articles";
@import "link-blocks";
@import "logos";
@import "map";
@import "membership-options";
@import "newsletter-cta";
@import "blog-articles";
@import "podcast-cta";
@import "pregnahub-blocks";
@import "subscription-blocks";
@import "teachers";
@import "teacher-badges";
@import "testimonials";
@import "whats-included";

//------ Miscellaneous ------//

@import "404";

//------ 53.125em = 850px ------//

@media screen and (max-width: 53.125em) {

    section {
        padding: 4em 0;
    }
    
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {
        padding: 2em 0;

        div.intro-wrapper {

            br {
                display: none;
            }

            p {
                max-width: 400px;

                &[style*="text-align: center;"] {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            a {

                &.btn {
                    margin-top: 0;
                }
            }
        }

        div.btn-wrapper {
            margin-top: 2em;
        }

        div.container {

            & > div.btn-wrapper {
                margin-top: 2em;
            }
        }
    }

}