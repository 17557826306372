//------ Accordion Blocks - Section Styles ------//

section {

    &.accordion-blocks {

        div.container {
            display: flex;
            flex-direction: row;
        	
            div.text-wrapper {
            	width: 33.3333333%;
            	color: $white;

                p {

                    &.uppercase {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            div.accordion-blocks {
                display: flex;
                gap: 1em;
                flex-wrap: wrap;
                height: fit-content;
            	width: 66.6666666%;
            	padding-left: 4em;

                // .safari &,
                // .ie & {
                    
                //     div.block {
                //         width: calc(50% - 1em);
                //         margin-top: 1em;
                //         margin-right: 1em;

                //         &.block-1,
                //         &.block-2 {
                //             margin-top: 0;
                //         }
                //     }
                // }

            	div.block {
            		background-color: $white;
            		border-radius: 20px;
                    width: calc(50% - 1em);
                    height: fit-content;

                    &.open {

                        div.block__heading {

                            span {

                                &.icon {
                                    transform: scaleY(-1);
                                }
                            }
                        }
                    }

            		div.block__heading {
            			position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 1em 1.5em;
                        justify-content: space-between;
                        transition: all 0.2s ease-in-out;
                        cursor: pointer;

                        span {

                            &.heading {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                span {

                                    &.text {
                                        font-family: $primary_600_font;
                                        font-weight: 600;
                                        line-height: 1.4em;
                                        padding-right: 0.5em;
                                    }

                                    &.icon {
                                        transition: all 0.2s ease-in-out;
                                        margin-right: 10px;

                                        img {
                                            width: auto;
                                            height: 20px;
                                            margin-top: 2px;
                                        }
                                    }   
                                }
                            }

                            &.icon {
                                transition: all 0.2s ease-in-out;

                                svg {
                                    width: 15px;
                                    height: auto;
                                    margin-top: 8px;

                                    path {
                                        stroke: $grey_5;
                                    }
                                }
                            }
                        }
            		}

            		div.block__content {
            			display: none;
                        padding: 0 1.5em;
                        margin-bottom: 1.5em;

                        p {

                            &:first-of-type {
                                margin-top: 0;
                            }
                        }

                        a {
                                
                            &:not(.btn) {
                                transition: all 0.2s ease-in-out;
                                border-bottom: 1px solid;
                                font-family: $primary_600_font;
                                font-weight: 600;

                                .no-touchevents &:hover {
                                    color: $grey_3;
                                    border-color: $grey_3;
                                }
                            }

                            &.btn {
                                margin-top: 1em;
                            }
                        }

                        ul, ol {
                            padding: 0.25em 0 0 0;
                            margin: 0 0 0 1.1em;

                            li {
                                line-height: 1.6em;
                            }
                        }
                    }
            	}
            }
        }
    }
}

//------ 62.5em = 1000px ------//

@media screen and (max-width: 62.5em) {

    section {

        &.accordion-blocks {

            div.container {
                flex-direction: column;

                div.text-wrapper,
                div.accordion-blocks {
                    width: 100%;
                }

                div.accordion-blocks {
                    padding-left: 0;
                    margin-top: 2em;

                    // .safari &,
                    // .ie & {
                        
                    //     div.block {
                    //         width: 100%;

                    //         &.block-1,
                    //         &.block-2 {
                    //             margin-top: 1em;
                    //         }
                    //     }
                    // }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.accordion-blocks {

            div.container {
                flex-direction: column;

                div.text-wrapper,
                div.accordion-blocks {
                    width: 100%;
                }

                div.accordion-blocks {
                    flex-direction: column;

                    div.block {
                        width: 100%;
                    }
                }
            }
        }
    }

}