//------ Article Banner - Section Styles ------//

section {

    &.article-banner {
        position: relative;
        padding: 4em 0 0 0;

        div.article-wrapper {
            display: flex;
            flex-direction: row;

            &.no-image {

                div.article-wrapper__content {

                    &.no-author {

                        div.author {
                            margin-top: 1em;
                        }
                    }

                    &.with-author {

                        div.author {
                            
                            div.author__info {
                                margin-top: 0;
                            }
                        }
                    }

                    div.content-inner {

                        &.grey-3-bg,
                        &.grey-4-bg,
                        &.grey-5-bg {
                            background-color: $white;
                        }

                        nav {

                            &.social-menu {

                                ul {

                                    li {
                                        border-color: $grey_5;

                                        .no-touchevents &:hover {
                                            background-color: $grey_5;

                                            svg {

                                                path {
                                                    fill: $white;
                                                }
                                            }
                                        }

                                        svg {

                                            path {
                                                fill: $grey_5;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    div.author {
                        display: flex;
                        align-items: center;
                        padding-left: 0;

                        span {

                            &.profile-image {

                                & + div.author__info {
                                    margin-left: 1em;
                                }
                            }
                        }
                    }
                }
            }

            &.with-image {

                div.article-wrapper__image {
                    position: relative;
                    width: 50%;

                    span {

                        &.spaces,
                        &.teachers {
                            position: absolute;
                            padding: 0.5em;
                            background-color: $white;
                            @include uppercase-text;
                            font-family: $primary_600_font;
                            font-size: 0.9em;
                            line-height: 1.4em;
                            z-index: 1;
                        }

                        &.spaces {

                            span.text {
                                position: relative;
                                top: 2px;
                                left: 2px;
                            }
                        }

                        &.teachers {

                            span.text {
                                position: relative;
                                top: 1px;
                                left: 1px;
                            }
                        }

                        &.spaces {
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        &.teachers {
                            left: 1em;
                            bottom: 1em;
                            width: fit-content;
                            margin-right: 2em;
                        }
                    }
                }

                div.article-wrapper__content {
                    width: 50%;

                    div.content-inner {
                        padding: 4em;

                        &.grey-3-bg,
                        &.grey-4-bg,
                        &.grey-5-bg {
                            color: $white;

                            div.content-inner__info {
                                color: $white;

                                time,
                                span {
                                    color: $white;
                                }
                            }

                            p, time, span {
                                color: $white;
                            }
                        }
                    }
                }
            }

            div.article-wrapper__image {
                width: 100%;
                overflow: hidden;
                margin-right: -1px;

                img {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 4/3;
                    object-fit: cover;
                }
            }

            div.article-wrapper__content {
                width: 100%;

                &.with-author {

                    div.content-inner {
                        padding-bottom: calc(2em + 35px);
                    }

                    div.author {
                        margin-top: -35px;

                        div.author__info {
                            margin-top: 1em;
                        }
                    }
                }

                div.content-inner {
                    position: relative;

                    div.content-inner__info {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 0.5em;

                        &:empty {
                            margin-bottom: 0;
                        }

                        time.date {
                            margin-bottom: 0;

                            & + span {

                                &.format {

                                    &:before {
                                        content: '\2022';
                                        font-size: 1em;
                                        line-height: 1em;
                                        vertical-align: middle;
                                        padding-left: 5px;
                                        padding-right: 5px;
                                    }
                                }
                            }
                        }

                        span {

                            &.format,
                            &.location,
                            &.category {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                ul {

                    &.teachers {
                        margin: 0 0 0.5em 0;
                        padding: 0;
                        line-height: 1.4em;
                        list-style-type: none;

                        li {
                            display: inline-block;
                            font-family: $primary_600_font;
                            @include uppercase-text;
                            font-size: 0.9em;
                            line-height: 1em;
                            margin-right: 5px;
                        }
                    }
                }

                h1 {
                    margin-bottom: 0.25em;

                    & + p {

                        &:first-of-type {
                            margin: 0;
                        }
                    }
                }

                p {

                    &.category {
                        margin-top: 0;
                    }

                    a {

                        &.btn {
                            margin-top: 1em;
                        }
                    }
                }

                nav {

                    &.social-menu {
                        margin-top: 2em;
                    }
                }

                div.author {
                    position: relative;
                    margin-top: 2em;
                    padding-left: 4em;
                    z-index: 1;

                    span {

                        &.profile-image {
                            display: block;
                            width: 75px;
                            height: 75px;
                            border-radius: 50%;
                            border: 4px solid $white;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    div.author__info {
                        display: flex;
                        flex-direction: row;

                        time.date {
                            margin-bottom: 0;

                            & + span {

                                &.author {

                                    &:before {
                                        content: '\2022';
                                        font-size: 1em;
                                        line-height: 1em;
                                        vertical-align: middle;
                                        padding-left: 5px;
                                        padding-right: 5px;
                                    }
                                }
                            }
                        }

                        span {

                            &.author {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }   
    }
}

//------ 62.5em = 1000px ------//

@media screen and (max-width: 62.5em) {

    section.article-banner {

        div.article-wrapper {
            flex-direction: column;

            div.article-wrapper__content {
                width: 100%;
            }

            &.with-image {

                div.article-wrapper__image {
                    width: 100%;
                }

                div.article-wrapper__content {
                    width: 100%;

                    div.content-inner {

                        .single-course & {
                            padding: 0;
                            margin-top: 2em;

                            &.grey-3-bg,
                            &.grey-4-bg,
                            &.grey-5-bg {
                                background-color: transparent;
                                color: $grey_5;

                                time, 
                                span,
                                p {
                                    color: $grey_5;  
                                }

                                a.btn {

                                    &.outline-white {
                                        color: $orange;
                                        border-color: $orange;
                                        background-color: transparent;

                                        .no-touchevents &:hover {
                                            background-color: $orange;
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.article-banner {
            padding: 2em 0 0 0;

            div.article-wrapper {

                &.with-image {

                    div.article-wrapper__content {

                        div.content-inner {
                            padding: 2em;
                        }

                        &.with-author {

                            div.author {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                margin-top: 2em;
                                padding-left: 0;

                                div.author__info {
                                    margin-left: 1em;
                                    margin-top: 0;
                                }
                            }
                        }

                        div.author {
                            padding-left: 0;
                        }
                    }
                }

                div.article-wrapper__content {

                    div.content-inner {

                        p {

                            a {

                                &.btn {
                                    margin-top: 0;
                                }
                            }
                        }
                    }

                    nav {

                        &.social-menu {
                            margin-top: 1em;
                        }
                    }
                }
            }   
        }
    }

}

@include breakpoint(ipad_landscape) {

    section {

        &.article-banner {

            div.article-wrapper {

                &.with-image {

                    div.article-wrapper__content {

                        div.content-inner {
                            padding: 3em;
                        }

                        div.author {
                            padding-left: 3em;
                        }
                    }
                }
            }   
        }
    }

}