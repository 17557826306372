//------ Latest Articles - Section Styles ------//

section {

    &.latest-articles {

        div.articles {

            &.grid-container {

                &.grid-one-third {

                	article {

                        &.default-article {

                            .ie & {
                                float: left;
                                width: 32%;
                                margin-right: 2%;
                                margin-bottom: 2%;

                                &:nth-of-type(3n) {
                                    margin-right: 0;
                                }
                            }

                            div.image-wrapper {
                                margin-bottom: 1.5em;
                            }
                        }
                	}
                }
            }
        }
    }
}

//------ 50em = 800px ------//

@media screen and (max-width: 50em) {

    section {

        &.latest-articles {

            div.articles {

                &.grid-container {

                    &.grid-one-third {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }

}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.latest-articles {

            div.articles {

                &.grid-container {

                    &.grid-one-third {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
        }
    }

}