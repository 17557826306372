//------ Image CTA - Section Styles ------//

section {

    &.image-cta {
        position: relative;
        overflow: hidden;
        text-align: center;

        &.with-image {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            color: $white;

            &.greyscale {
                filter: grayscale(100%);
            }

            span {

                &.overlay {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $black;
                    z-index: 0;
                }
            }

            div.text-wrapper {

                p {
                    color: $white;

                    strong {
                        color: $white;
                    } 
                }

                a {
                        
                    &:not(.btn) {
                        color: $white;
                    }
                }
            }
        }

        div.text-wrapper {
            position: relative;

            a {

                &:not(.btn) {
                    transition: all 0.2s ease-in-out;
                    border-bottom: 1px solid;
                    font-family: $primary_600_font;
                    font-weight: 600;

                    .no-touchevents &:hover {
                        color: $grey_3;
                        border-color: $grey_3;
                    }
                }

                &.btn {
                    margin-top: 1em;
                }
            }
        }
    }
}

//------ 37.5em = 600px ------//

@media screen and (max-width: 37.5em) {

    section {

        &.image-cta {

            div.text-wrapper {

                br {
                    display: none;
                }

                a {

                    &.btn {
                        margin-top: 0;
                    }
                }
            }
        }
    }

}